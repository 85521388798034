import { createRef, RefObject, useEffect, useState } from "react";

import { useFlowDispatchContext } from "../../context/FlowContext";
import { checkDepsWithinQuestionField, flowSets } from "../../services/flow";
import { getBinIcon } from "../../services/icons";
import {
  FlowAnswer,
  FlowField,
  FlowItem,
  FlowItemErrors,
  FlowType,
} from "../../types/flow";
import SectionDivider from "../SectionDivider";
import { Question } from "./Question";

type PropsType = {
  flow: FlowType;
  item: FlowItem;
  questions: FlowItem[];
  getFieldValue: {
    item: FlowItem;
    name: string;
    single: boolean;
    index: number;
  };
  validateField: (
    item: FlowItem,
    field: FlowField,
    value: string | string[],
    index: number,
    errors?: FlowItemErrors,
    updateErrors?: boolean
  ) => FlowItemErrors;
  showErrors: boolean;
};

type NumType = { index: number };

export default function MultipleQuestion({
  flow,
  item,
  getFieldValue,
  validateField,
  questions,
  showErrors,
}: PropsType) {
  const [num, setNum] = useState([] as NumType[]);

  const dispatchFlow = useFlowDispatchContext();

  useEffect(() => {
    const errors = { ...flow.errors };
    let updateErrors = false;

    const values = [...flow.values];
    const valuesForItem = values.find((v) => v.name === item.multiple);
    const valuesForItemIndex = values.findIndex(
      (v) => v.name === item.multiple
    );
    let updateValues = false;

    num.map((n) => {
      const index = n.index;
      let valuesForNum = valuesForItem ? valuesForItem.value[index] : [];
      item.items.map((itm) => {
        if (!itm.dependencies) {
          return;
        }
        itm.fields.map((f) => {
          const fieldName = `${item.multiple}-${index}-${f.name}`;
          const toShow = checkDepsWithinQuestionField(itm, flow, item, index);
          const val = getFieldValue(item, f.name, true, index);
          if (!toShow && errors[fieldName]) {
            updateErrors = true;
            delete errors[fieldName];
          }
          if (!toShow && val !== null) {
            valuesForNum = valuesForNum.reduce((acc, i) => {
              if (i.name !== f.name) {
                return [...acc, i];
              }
              return acc;
            }, []);
            updateValues = true;
          }
          if (toShow && val === null) {
            valuesForNum.push({ name: f.name, value: [""] });
            updateValues = true;
          }
        });
      });
      if (valuesForItem?.value[index]) {
        values[valuesForItemIndex].value[index] = valuesForNum;
      }
    });

    if (JSON.stringify(flow.values) === JSON.stringify(values))
      updateValues = false;
    if (JSON.stringify(flow.errors) === JSON.stringify(errors))
      updateErrors = false;
    if (updateErrors) {
      dispatchFlow({ type: "set-errors", payload: { errors: errors } });
    }
    if (updateValues) {
      dispatchFlow({ type: "set-values-exact", payload: { values: values } });
    }
  }, [flow.values]);

  useEffect(() => {
    const setData = flowSets.find(
      (set) => set.id === flow.set && set.section === flow.section
    );

    let currentItem = flow.values.filter((el) => el.name === item.multiple);
    if (setData?.multiple) {
      const setValues = flow.values.find((el) => el.name === setData.multiple);
      currentItem =
        setValues && setValues.value[flow.setIndex]
          ? setValues.value[flow.setIndex].filter(
              (el) => el.name === item.multiple
            )
          : [];
    }

    const keys =
      currentItem.length > 0 ? Object.keys(currentItem[0].value) : [0];
    const out = [...num];
    keys.map(function (i) {
      addDefaultValue(i);
      out.push({ index: parseInt(i) });
    });
    setNum(out);
  }, []);

  const addDefaultValue = (index: number, saveErrors = true) => {
    let errors = { ...flow.errors };
    item.items.map((itm) => {
      itm.fields.map((f) => {
        const toShow = checkDepsWithinQuestionField(itm, flow, item, index);
        const val = getFieldValue(item, f.name, true, index);
        if (toShow && val === null) {
          errors = setInitialFieldValue(item, f, "", index, errors);
        }
      });
    });

    if (saveErrors) {
      dispatchFlow({ type: "set-errors", payload: { errors: errors } });
    }

    return errors;
  };

  const addItem = () => {
    const topVal = num.reduce((acc, n) => {
      const i = parseInt(n.index);
      if (i > acc) {
        acc = i;
      }
      return acc;
    }, 0);
    const currentCount = parseInt(topVal + 1);
    addDefaultValue(currentCount);
    setNum((p) => [...p, { index: currentCount }]);
  };

  const removeItem = (el: NumType, i: FlowItem) => {
    const index = el.index;

    const currentValues = [...flow.values];
    const currentErrors = { ...flow.errors };

    const newValues = currentValues.reduce((acc, row) => {
      const tempRow = { ...row };
      if (tempRow.id === item.id) {
        const values = { ...tempRow.value };
        const nv = Object.entries(values).reduce((a, v) => {
          const [key, value] = v;
          const indexString = index.toString();
          if (key !== indexString) {
            a[key] = value;
          }
          return a;
        }, {});
        tempRow.value = nv;
      }
      return [...acc, tempRow];
    }, [] as FlowAnswer[]);

    dispatchFlow({
      type: "set-values-exact",
      payload: {
        values: newValues,
      },
    });

    // delete error messages
    const fieldNames = i.items.reduce((a, ii) => {
      const ia = ii.fields.reduce((acc, cur) => {
        return [...acc, cur.name];
      }, []);
      return [...a, ...ia];
    }, [] as string[]);
    if (fieldNames.length > 0 && i.multiple) {
      fieldNames.map((fn) => {
        delete currentErrors[`${i.multiple}-${index}-${fn}`];
      });
    }

    dispatchFlow({
      type: "set-errors",
      payload: {
        errors: currentErrors,
      },
    });

    const newNum = [...num].reduce((acc, n) => {
      if (index !== n.index) {
        return [...acc, n];
      }
      return acc;
    }, [] as NumType[]);
    setNum(newNum);
  };

  const setFieldValue = (
    item: FlowItem,
    field: FlowField,
    value: string | string[],
    index: number
  ) => {
    let v = value;
    if (typeof value === "string") {
      v = [value];
    }

    validateField(item, field, value, index);

    dispatchFlow({
      type: "set-values",
      payload: {
        id: item.id,
        multiple: item.multiple,
        name: field.name,
        value: v,
        index: index,
        section: flow.section,
        set: flow.set,
      },
    });
  };

  const setInitialFieldValue = (
    item: FlowItem,
    field: FlowField,
    value: string | string[],
    index: number,
    errors: FlowItemErrors
  ) => {
    let v = value;
    if (typeof value === "string") {
      v = [value];
    }

    const err = validateField(item, field, value, index, errors, false);

    dispatchFlow({
      type: "set-values",
      payload: {
        id: item.id,
        multiple: item.multiple,
        name: field.name,
        value: v,
        index: index,
        section: flow.section,
        set: flow.set,
      },
    });
    return err;
  };

  return (
    <div className={item.wrapperClasses}>
      {item.subsectionTitle && <SectionDivider title={item.subsectionTitle} />}
      <div className="grid grid-cols-1 gap-5 lg:auto-rows-fr lg:grid-cols-2">
        {num.map(function (x) {
          return (
            <div key={`${item.id}-${x.index}`} className="relative">
              <Question
                index={x.index}
                item={item}
                questions={questions}
                getFieldValue={getFieldValue}
                setFieldValue={setFieldValue}
                showErrors={showErrors}
              />
              {num.length > 1 && (
                <div
                  className="absolute top-[10px] right-[10px] cursor-pointer"
                  onClick={() => removeItem(x, item)}
                >
                  {getBinIcon("#0C2632", "20", "100%")}
                </div>
              )}
            </div>
          );
        })}
        {(!item.multipleLimit || item.multipleLimit < num) && (
          <div
            className="flex cursor-pointer flex-col items-center justify-center border-theme-neutral3 py-2 lg:rounded-[4px] lg:border-[1px] lg:border-dashed lg:py-5"
            onClick={addItem}
          >
            <div className="flex flex-row-reverse gap-2 tracking-[0.12em] lg:flex-col lg:items-center lg:justify-center">
              <div className="items-center justify-center rounded-full text-center text-[20px] leading-none transition hover:bg-theme-primary3 lg:flex lg:h-[36px] lg:w-[36px] lg:bg-[#000000] lg:text-white">
                +
              </div>
              <div className="border-b-[1px] border-theme-primary1 font-intermedium text-[14px] uppercase lg:border-none">
                Lägg till en till
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
