import config from "../config";
import { FlowType } from "../types/flow";
import { IdaDocumentListType, IdaDocumentType } from "../types/ida";
import { UserProfileType } from "../types/user";
import { logErrors } from "./utils";

export const apiUrl = config.ida_api_url;
export const flowApiUrl = config.flow_api_url;

export const CUSTOMER_EVENT_ANALYSIS_STARTED = "AnalysisStarted";
export const CUSTOMER_EVENT_ANALYSIS_COMPLETE = "AnalysisComplete";
export const CUSTOMER_EVENT_INITIATED_CONTRACT = "InitiatedContract";
export const CUSTOMER_EVENT_LOGGED_IN = "LoggedIn";

export const CUSTOMER_EVENT_ANALYSIS_BASIC = "1";
export const CUSTOMER_EVENT_ANALYSIS_WISHES = "2";
export const CUSTOMER_EVENT_ANALYSIS_ASSETS = "3";
export const CUSTOMER_EVENT_INITIATED_CONTRACT_WILL = "144";

export const CUSTOMER_EVENT_DATA_SHARE_CONSENT = "9";

const tenantId = 1;

export const getProfileInfo = async (accessToken: string) => {
  try {
    const response = await fetch(
      `${apiUrl}/public/customer/profile?tenantId=${tenantId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (!response.ok) {
      logErrors(
        `Unable to fetch data. ${response.status}: ${response.statusText}`,
        { status: response.status }
      );
      return;
    }
    const data = (await response.json()) as { object: UserProfileType };
    if (!data.object) {
      logErrors("No data in response");
      return;
    }

    return data.object;
  } catch (error) {
    logErrors(error);
  }
};

export const updateProfileInfo = async (
  accessToken: string,
  data: UserProfileType
) => {
  try {
    const response = await fetch(
      `${apiUrl}/public/customer/profile?tenantId=${tenantId}`,
      {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (!response.ok) {
      logErrors(
        `Unable to fetch data. ${response.status}: ${response.statusText}`,
        { status: response.status }
      );
      return;
    }

    const dt = (await response.json()) as { object: UserProfileType };
    if (!dt.object) {
      logErrors("No data in response");
      return;
    }
    return dt.object;
  } catch (error) {
    logErrors(error);
  }
};

export const getUserDataByKey = async (accessToken: string, key: string) => {
  try {
    const response = await fetch(`${apiUrl}/public/customer/datastore/${key}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (!response.ok) {
      logErrors(
        `Unable to fetch data. ${response.status}: ${response.statusText}`,
        { status: response.status }
      );
      return;
    }

    const data = (await response.json()) as { object: { value: string } };
    if (!data.object) {
      return null;
    }

    return data.object;
  } catch (error) {
    logErrors(error);
  }
};

export const setUserData = async (
  accessToken: string,
  dt: { key: string; value: string }[]
) => {
  try {
    const response = await fetch(`${apiUrl}/public/customer/datastore`, {
      method: "PUT",
      body: JSON.stringify(dt),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (!response.ok) {
      logErrors(
        `Unable to fetch data. ${response.status}: ${response.statusText}`,
        { status: response.status }
      );
      return;
    }

    const data = (await response.json()) as { object: any };
    if (!data.object) {
      logErrors("No data in response");
      return;
    }

    return data.object;
  } catch (error) {
    logErrors(error);
  }
};

export const getPackageById = async (accessToken: string, id: number) => {
  try {
    const response = await fetch(`${apiUrl}/public/package/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      logErrors(
        `Unable to fetch data. ${response.status}: ${response.statusText}`,
        { status: response.status }
      );
      return;
    }

    const data = (await response.json()) as {
      object: { totalDetails: { total: number } };
    };
    if (!data.object) {
      logErrors("No data in response");
      return;
    }

    return data.object;
  } catch (error) {
    logErrors(error);
  }
};

export const setUserPackage = async (
  accessToken: string,
  packageId: number,
  invId?: string
) => {
  try {
    const dt: { source: string; packageId?: number; invitationId?: string } = {
      source: "PreNeedApp"
    };
    if (packageId) {
      dt.packageId = packageId;
    }
    if (invId) {
      dt.invitationId = invId;
    }
    const response = await fetch(
      `${apiUrl}/public/customer/package?tenantId=${tenantId}`,
      {
        method: "POST",
        body: JSON.stringify(dt),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      logErrors(
        `Unable to fetch data. ${response.status}: ${response.statusText}`,
        { status: response.status }
      );
      return;
    }

    const data = await response.json();
    if (!data.object) {
      logErrors("No data in response");
      return;
    }

    return data.object;
  } catch (error) {
    logErrors(error);
  }
};

export const getUserPackage = async (accessToken: string) => {
  try {
    const response = await fetch(`${apiUrl}/public/customer/package`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      logErrors(
        `Unable to fetch data. ${response.status}: ${response.statusText}`,
        { status: response.status }
      );
      return;
    }

    const data = await response.json();
    if (!data.object) {
      logErrors("No data in response");
      return;
    }

    return data.object;
  } catch (error) {
    logErrors(error);
  }
};

export const getPaymentClientSecret = async (
  accessToken: string,
  stripeSubscriptionId: string,
  packageId: string
) => {
  try {
    const params = new URLSearchParams({
      sid: stripeSubscriptionId,
      cpid: packageId,
    });

    const response = await fetch(
      `${apiUrl}/public/payment/stripe/clientsecret?${params.toString()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      logErrors(
        `Unable to fetch data. ${response.status}: ${response.statusText}`,
        { status: response.status }
      );
      return;
    }

    const data = await response.json();
    if (!data) {
      logErrors("No data in response");
      return;
    }

    return data;
  } catch (error) {
    logErrors(error);
  }
};

export const createFlow = async (accessToken: string, flow: FlowType) => {
  try {
    const dt = {
      title: "conversationPublicData",
      typeFlow: "MainFlowPublic",
      status: "published",
      jsonData: flow,
    };
    const response = await fetch(`${flowApiUrl}/flows`, {
      method: "POST",
      body: JSON.stringify(dt),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (!response.ok) {
      logErrors(
        `Unable to fetch data. ${response.status}: ${response.statusText}`,
        { status: response.status }
      );
      return;
    }

    const data = await response.json();
    if (!data) {
      logErrors("No data in response");
      return;
    }
    return data;
  } catch (error) {
    logErrors(error);
  }
};

export const createSession = async (
  accessToken: string,
  userId: string | number,
  flow,
  flowData
) => {
  try {
    const dt = {
      idUser: userId,
      idFlow: flow,
      status: "in progress", // "in progress" | "finished"
      jsonData: flowData,
    };
    const response = await fetch(`${flowApiUrl}/sessions`, {
      method: "POST",
      body: JSON.stringify(dt),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (!response.ok) {
      logErrors(
        `Unable to fetch data. ${response.status}: ${response.statusText}`,
        { status: response.status }
      );
      return;
    }
    const data = await response.json();
    if (!data) {
      logErrors("No data in response");
      return;
    }
    return data;
  } catch (error) {
    logErrors(error);
  }
};

export const updateSession = async (
  accessToken: string,
  sessionId,
  status,
  flowData
) => {
  try {
    const dt = {};
    if (status) {
      dt.status = status;
    }
    if (flowData) {
      dt.jsonData = flowData;
    }
    const response = await fetch(`${flowApiUrl}/sessions/${sessionId}`, {
      method: "PUT",
      body: JSON.stringify(dt),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (!response.ok) {
      logErrors(
        `Unable to fetch data. ${response.status}: ${response.statusText}`,
        { status: response.status }
      );
      return;
    }
    const data = await response.json();
    if (!data) {
      logErrors("No data in response");
      return;
    }

    return data;
  } catch (error) {
    logErrors(error);
  }
};

export const getDocFromSession = async (
  accessToken: string,
  sessionId,
  typeId
) => {
  try {
    const response = await fetch(
      `${flowApiUrl}/sessions/${sessionId}/pdf/${typeId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (!response.ok) {
      logErrors(
        `Unable to fetch data. ${response.status}: ${response.statusText}`,
        { status: response.status }
      );
      return;
    }
    const data = await response.blob();
    if (!data) {
      logErrors("No data in response");
      return;
    }

    return data;
  } catch (error) {
    logErrors(error);
  }
};

export const getDocumentList = async (accessToken: string) => {
  try {
    const response = await fetch(
      `${apiUrl}/public/customer/document/list/type/1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (!response.ok) {
      logErrors(
        `Unable to fetch data. ${response.status}: ${response.statusText}`,
        { status: response.status }
      );
      return;
    }
    const data = (await response.json()) as { object: IdaDocumentListType[] };
    if (!data.object) {
      logErrors("No data in response");
      return;
    }

    return data.object;
  } catch (error) {
    logErrors(error);
  }
};

export const getDocumentByID = async (
  accessToken: string,
  id: string | number
) => {
  try {
    const response = await fetch(`${apiUrl}/public/customer/document/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (!response.ok) {
      logErrors(
        `Unable to fetch data. ${response.status}: ${response.statusText}`,
        { status: response.status }
      );
      return;
    }
    const data = (await response.json()) as { object: IdaDocumentType };
    if (!data.object) {
      logErrors("No data in response");
      return;
    }

    return data.object;
  } catch (error) {
    logErrors(error);
  }
};

export const sendInternalEmail = async (
  accessToken: string,
  subject: string,
  message: string
) => {
  try {
    const dt = {
      subject: subject,
      message: message,
    };
    const response = await fetch(`${apiUrl}/public/customer/email`, {
      method: "POST",
      body: JSON.stringify(dt),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (!response.ok) {
      logErrors(
        `Unable to fetch data. ${response.status}: ${response.statusText}`,
        { status: response.status }
      );
      return;
    }
    const data = await response.json();
    if (!data.success) {
      logErrors("No data in response");
      return;
    }

    return data.success;
  } catch (error) {
    logErrors(error);
  }
};

export const sendCustomerEvent = async (
  accessToken: string,
  status: string,
  details: string,
  partnerID?: string
) => {
  try {
    const dt: { status: string; source: string; eventDetails: string; partnerId?: string } = {
      status: status,
      source: "PreNeedApp",
      eventDetails: details,
    };
    if (partnerID) {
      dt.partnerId = partnerID;
    }
    const response = await fetch(`${apiUrl}/public/customer/event`, {
      method: "POST",
      body: JSON.stringify(dt),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (!response.ok) {
      logErrors(
        `Unable to fetch data. ${response.status}: ${response.statusText}`,
        { status: response.status }
      );
      return;
    }

    return true;
  } catch (error) {
    logErrors(error);
  }
};
