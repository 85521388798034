import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useEffect,
  useReducer,
} from "react";

import config from "../config";
import {
  FLOW_DOC_LPA_ID,
  FLOW_DOC_LPA_TYPE,
  FLOW_DOC_PP_ID,
  FLOW_DOC_PP_LETTER_ID,
  FLOW_DOC_PP_LETTER_TYPE,
  FLOW_DOC_PP_TYPE,
  FLOW_DOC_WILL_ID,
  FLOW_DOC_WILL_TYPE,
  flowData,
  flowSets,
  LPA_PERSON_FIELDS,
} from "../services/flow";
import {
  createSession,
  getUserDataByKey,
  setUserData,
  updateSession,
} from "../services/ida";
import { getPartnerByUrl } from "../services/partners";
import {
  flowKey,
  getFromStorage,
  partnerKey,
  removeFromStorage,
  setInStorage,
} from "../services/storage";
import {
  dataDeepCopy,
  isFieldValid,
  isLPAEnabled,
  isPreplanEnabled,
  joinArrayWithAnd,
} from "../services/utils";
import {
  FlowActionClear,
  FlowActionType,
  FlowAnswer,
  FlowAsset,
  FlowContextType,
  FlowDocType,
  FlowInvite,
  FlowItemDependency,
  FlowPerson,
  FlowPosition,
  FlowType,
} from "../types/flow";
import {
  checkIfDependenciesMet,
  getDataFromAssetsSection,
  getDataFromLPASection,
  getDataFromPrePlanSection,
  getNextSet,
  getPersonsFromGeneralSection,
  getPersonsFromObjectivesSection,
  pushUserDataToStorage,
  savePartnerAsHeir,
  setValues,
} from "./helpers/flow";
import { useUserContext } from "./UserContext";

const defaultFLow: FlowType = {
  section: 1,
  set: 1,
  setIndex: 0,
  status: "init",
  items: [],
  values: [],
  persons: [],
  assets: [],
  errors: {},
  updatePersons: false,
  docs: [
    {
      id: FLOW_DOC_WILL_ID,
      type: FLOW_DOC_WILL_TYPE,
      ready: false,
      view: false,
    },
    {
      id: FLOW_DOC_PP_LETTER_ID,
      type: FLOW_DOC_PP_LETTER_TYPE,
      ready: false,
      view: false,
    },
    { id: FLOW_DOC_PP_ID, type: FLOW_DOC_PP_TYPE, ready: false, view: false },
    { id: FLOW_DOC_LPA_ID, type: FLOW_DOC_LPA_TYPE, ready: false, view: false },
  ],
};
const initialFlow = getFromStorage(flowKey, defaultFLow);

const FlowContext = createContext({} as FlowContextType);
const FlowDispatchContext = createContext(
  null as unknown as Dispatch<FlowActionType>
);

const flowReducer = (state: FlowType, action: FlowActionType): FlowType => {
  switch (action.type) {
    case "set-items":
      return {
        ...state,
        items: action.payload.items,
      };
    case "set-next-set": {
      const st: FlowType = { ...state, items: [] };
      st.errors = {};

      const nextSet = state.set + 1;
      const set = getNextSet(state, nextSet);
      if (set) {
        st.set = set;
        st.status = "started";
      } else {
        st.status = "finished";
      }

      pushUserDataToStorage(action.payload.accessToken, st);

      return st;
    }
    case "set-set": {
      const st: FlowType = { ...state, set: action.payload.set };

      pushUserDataToStorage(action.payload.accessToken, st);

      return st;
    }
    case "set-section-start": {
      const st: FlowType = {
        ...state,
        section: action.payload.section,
        set: 1,
        status: "started",
        items: [],
        errors: {},
      };
      pushUserDataToStorage(action.payload.accessToken, st);

      return { ...st, updatePersons: !!action.payload.updatePersons };
    }
    case "set-status": {
      const st: FlowType = {
        ...state,
        status: action.payload.status,
        items: [],
      };
      if (action.payload.section) {
        st.section = action.payload.section;
      }
      if (action.payload.set) {
        st.set = action.payload.set;
      }

      pushUserDataToStorage(action.payload.accessToken, st);

      return st;
    }
    case "set-edit": {
      const st: FlowType = {
        ...state,
        set: action.payload.set,
        section: action.payload.section,
        setIndex: action.payload.setIndex ? action.payload.setIndex : 0,
        status: action.payload.status ? action.payload.status : "edit",
      };
      if (!state.prevPosition && action.payload.prevPosition) {
        st.prevPosition = action.payload.prevPosition;
      }
      return st;
    }
    case "set-edit-set-item":
      return {
        ...state,
        setIndex: action.payload.setIndex,
        status: action.payload.status,
      };
    case "set-multiple-set":
      return {
        ...state,
        set: action.payload.set,
        status: action.payload.status,
        setIndex: action.payload.setIndex,
      };
    case "set-ppcamp-data": {
      const st: FlowType = {
        ...state,
        ppCamp: action.payload.ppCamp,
      };
      if (action.payload.values) {
        st.values = [...state.values, ...action.payload.values];
      }

      pushUserDataToStorage(action.payload.accessToken, st);
      return st;
    }
    case "set-values": {
      const [updatedValues, nextPositions] = setValues(state, action.payload);
      const st: FlowType = { ...state, values: [...updatedValues] };
      if (nextPositions && typeof nextPositions === "object") {
        st.nextPositions = nextPositions;
      }
      return st;
    }
    case "set-docs": {
      const st: FlowType = {
        ...state,
        docs: action.payload.docs,
      };
      if (action.payload.sessionUpdateStatus) {
        st.sessionUpdateStatus = action.payload.sessionUpdateStatus;
      }
      if (action.payload.accessToken) {
        pushUserDataToStorage(action.payload.accessToken, st);
      }
      return st;
    }
    case "set-session": {
      const st: FlowType = { ...state };
      st.session = action.payload.session;
      pushUserDataToStorage(action.payload.accessToken, st);
      return st;
    }
    case "set-values-exact":
      return { ...state, values: action.payload.values };
    case "set-from-storage": {
      return {
        ...defaultFLow,
        ...state,
        ...action.payload.flow,
      };
    }
    case "set-errors":
      return {
        ...state,
        errors: action.payload.errors,
      };
    case "set-package-invitation": {
      const st = { ...state };
      if (action.payload.package) {
        st.pkg = action.payload.package;
      }
      if (action.payload.invitation) {
        st.inv = action.payload.invitation;
      }
      return st;
    }
    case "set-package-id-override":
      return {
        ...state,
        pkgOvr: action.payload,
      };
    case "set-next-positions": {
      const nextPositions = [...action.payload.nextPositions];
      nextPositions.sort((a, b) => {
        const aId = parseFloat(`${a.section}${a.set}${a.setIndex}`);
        const bId = parseFloat(`${b.section}${b.set}${a.setIndex}`);
        if (aId < bId) {
          return -1;
        } else if (aId > bId) {
          return 1;
        }
        return 0;
      });
      return { ...state, nextPositions };
    }
    case "continue-next-position": {
      if (!state.nextPositions || !state.nextPositions.length) {
        return state;
      }
      let nextPositions = [...state.nextPositions];
      const pos = nextPositions[0];
      nextPositions = nextPositions.slice(1);

      let validate = pos.validate;
      if (validate === undefined) {
        validate = pos.status !== "edit-finished";
      }

      const next = {
        ...state,
        nextPositions,
        section: pos.section,
        set: pos.set,
        setIndex: pos.setIndex,
        status: pos.status,
        validate,
      };

      return next;
    }
    case "save-whole-flow-data": {
      const st: FlowType = {
        ...state,
        values: action.payload.values,
        persons: action.payload.persons,
        assets: action.payload.assets,
        docs: action.payload.docs,
        sessionUpdateStatus: true,
      };
      if (action.payload.accessToken) {
        pushUserDataToStorage(action.payload.accessToken, st);
      }
      if (action.payload.sessionUpdateStatus) {
        st.sessionUpdateStatus = action.payload.sessionUpdateStatus;
      }
      return st;
    }
    case "restore-position": {
      const st: FlowType = {
        ...state,
        status: action.payload.status,
        section: action.payload.section,
        set: action.payload.set,
        setIndex: action.payload.setIndex,
        validate: false,
        items: [],
      };

      delete st.prevPosition;

      const ppValue = state.values.find((v) => v.name === "preplan-funeral");
      const isPPEnabled = ppValue && ppValue.value.toString() === "yes";

      const lpaValue = state.values.find((v) => v.name === "write-lpa");
      const isLpaEnabled = lpaValue && lpaValue.value.toString() === "yes";

      if (action.payload.section === 5 && !isPPEnabled && isLpaEnabled) {
        st.section = 4;
        st.status = "finished";
      }

      if (
        (action.payload.section === 5 && !isPPEnabled && !isLpaEnabled) ||
        (action.payload.section === 4 && !isLpaEnabled)
      ) {
        st.section = 3;
        st.status = "finished";
      }

      if (action.payload.accessToken) {
        pushUserDataToStorage(action.payload.accessToken, st);
      }

      return st;
    }
    case "add-persons": {
      const st: FlowType = {
        ...state,
        persons: [...state.persons, ...action.payload.persons],
      };
      pushUserDataToStorage(action.payload.accessToken, st);
      return st;
    }
    case "set-persons-exact": {
      const st: FlowType = {
        ...state,
        persons: action.payload.persons,
      };
      pushUserDataToStorage(action.payload.accessToken, st);
      return st;
    }
    case "add-assets": {
      const st: FlowType = {
        ...state,
        assets: [...state.assets, ...action.payload.assets],
      };
      pushUserDataToStorage(action.payload.accessToken, st);
      return st;
    }
    case "update-persons": {
      const st: FlowType = {
        ...state,
        persons: action.payload.persons,
      };
      if (action.payload.accessToken) {
        pushUserDataToStorage(action.payload.accessToken, st);
      }
      return st;
    }
    case "update-payment-status": {
      const st = { ...state, paymentStatus: action.payload.paymentStatus };
      if (action.payload.accessToken) {
        pushUserDataToStorage(action.payload.accessToken, st);
      }
      return st;
    }
    case "update-session-data": {
      const st = {
        ...state,
        sessionUpdateStatus: action.payload.sessionUpdateStatus,
      };
      pushUserDataToStorage(action.payload.accessToken, st);
      return st;
    }
    case "update-doc": {
      const type = action.payload.type;
      const ready = action.payload.ready;
      const view = action.payload.view;

      const docs = state.docs.reduce((acc, d) => {
        if (d.type === type) {
          d.ready = ready;
          if (view) {
            d.view = view;
          }
        }
        return [...acc, d];
      }, [] as FlowDocType[]);

      const st: FlowType = {
        ...state,
        docs: docs,
      };

      if (action.payload.accessToken) {
        pushUserDataToStorage(action.payload.accessToken, st);
      }
      return st;
    }
    case "clear-update-persons":
      return { ...state, updatePersons: false };
    case "clear-validate":
      return { ...state, validate: false };
    case "clear": {
      const st: FlowType = {
        ...defaultFLow,
        pkg: state.pkg,
        status: "started",
      };
      if (state.inv) {
        st.inv = state.inv;
      }

      if (action.payload.restarted !== undefined) {
        st.restarted = action.payload.restarted;
      }

      return st;
    }
    case "add-history": {
      const oldHistory = state.history ? state.history : [];
      return {
        ...state,
        history: [...oldHistory, { ...action.payload }],
      };
    }
    case "prev-history": {
      const hist = state.history ? [...state.history] : [];
      if (hist.length <= 1) {
        return state;
      }

      hist.pop();
      const prev = hist[hist.length - 1];
      return { ...state, ...prev, history: hist, errors: {} };
    }
    case "set-invitation-id":
      return {
        ...state,
        inv: action.payload,
      };
  }
  return state;
};

export const FlowProvider = ({ children }: PropsWithChildren) => {
  const [flow, dispatch] = useReducer(flowReducer, initialFlow);

  const { user } = useUserContext();

  useEffect(() => {
    const f = { ...flow };
    delete f["errors"];
    setInStorage(flowKey, f);
  }, [flow]);

  useEffect(() => {
    if (flow.sessionUpdateStatus) {
      void updateDataInSession();
      dispatch({
        type: "update-session-data",
        payload: { sessionUpdateStatus: false },
      });
    }
  }, [flow.sessionUpdateStatus]);

  useEffect(() => {
    if (!["edit"].includes(flow.status) || flow.section !== 3) {
      return;
    }

    let values = dataDeepCopy(flow.values);
    let nextPositions = flow.nextPositions
      ? dataDeepCopy(flow.nextPositions)
      : [];
    let updateNextPositions = false;
    let updateValues = false;

    flowSets.map((s) => {
      if (!s.dependencies) {
        return;
      }
      const shouldExist = checkIfDependenciesMet(s, flow, s);
      const dep: FlowItemDependency = s.dependencies[0];
      const val = dep.type === "value" ? dep.values.toString() : null;
      const isInAnswers = values.find((v) => v.name === val);
      const isInNextPositions = nextPositions.find(
        (np) => np.section === s.section && np.set === s.id
      );
      const ifWentOverPosition =
        flow.prevPosition && flow.prevPosition.section >= s.section;

      let verdict = "nothing";
      if (shouldExist && !isInAnswers) {
        verdict = `add ${s.section}:${s.id}; over: ${ifWentOverPosition}`;

        if (!isInNextPositions && ifWentOverPosition) {
          // if (!isInNextPositions) {
          nextPositions.push({
            section: s.section,
            set: s.id,
            setIndex: s.multiple ? 1 : 0,
            status: s.multiple ? "edit-finished" : "edit",
          });
          updateNextPositions = true;
        }
      }
      if (!shouldExist && isInAnswers) {
        verdict = `remove ${isInAnswers.section}:${isInAnswers.set}`;

        values = values.reduce((acc, vl) => {
          if (vl.section === s.section && vl.set === s.id) {
            return acc;
          }
          return [...acc, vl];
        }, [] as FlowAnswer[]);
        updateValues = true;
      }
      if (!shouldExist && isInNextPositions) {
        nextPositions = nextPositions.reduce((acc, np) => {
          if (np.section === s.section && np.set === s.id) {
            updateNextPositions = true;
            return [...acc];
          }
          return [...acc, np];
        }, [] as FlowPosition[]);
      }

      // console.log(s.title, shouldExist, isInAnswers, verdict);
    });

    if (updateNextPositions) {
      dispatch({ type: "set-next-positions", payload: { nextPositions } });
    }
    if (updateValues) {
      dispatch({ type: "set-values-exact", payload: { values } });
    }
  }, [flow.status, flow.values]);

  useEffect(() => {
    const history = flow.history ? [...flow.history] : [];
    const isInHistory = history.find(
      (h) =>
        h.section === flow.section &&
        h.set === flow.set &&
        h.setIndex === flow.setIndex
    );
    if (!isInHistory && flow.status !== "summary") {
      dispatch({
        type: "add-history",
        payload: {
          section: flow.section,
          set: flow.set,
          setIndex: flow.setIndex,
          status: flow.status,
        },
      });
    }
  }, [flow.section, flow.set, flow.setIndex, flow.status]);

  const getSetOfItems = (f) => {
    const section = f.section;
    const set = f.set;
    return flowData.filter((el) => {
      if (el.set !== set || el.section !== section) {
        return false;
      }

      return checkIfDependenciesMet(el, f);
    });
  };

  const getSetList = (f) => {
    const setItems = getSetOfItems(f);

    dispatch({
      type: "set-items",
      payload: {
        items: setItems,
      },
    });
  };

  const getPersonList = (section = 0) => {
    let [values, persons] = getPersonsFromGeneralSection(flow.values);

    switch (section) {
      case 2:
        break;
      case 3:
      case 4:
        [values, persons] = getPersonsFromObjectivesSection(values, persons);
        [values, persons] = getDataFromAssetsSection(values, persons);
        break;
      case 5:
        [values, persons] = getPersonsFromObjectivesSection(values, persons);
        [values, persons] = getDataFromAssetsSection(values, persons);
        [values, persons] = getDataFromLPASection(values, persons);
      default: {
        // section 2
        [values, persons] = getPersonsFromObjectivesSection(values, persons);
      }
    }

    return persons;
  };

  const updatePersonalNumbers = (name = "persons-numbers") => {
    const pnObject = flow.values.find((el) => el.name === name);
    if (!pnObject) {
      return false;
    }
    const persons = [...flow.persons];
    pnObject.value.map((el) => {
      persons.map((person) => {
        if (person.id === el.id) {
          person["personalNumber"] = el.personalNumber;
        }
        return person;
      });
    });

    dispatch({
      type: "update-persons",
      payload: {
        persons: persons,
      },
    });
  };

  const saveWholeFlowData = (d?: FlowDocType[]) => {
    if (!user.accessToken) {
      return;
    }

    let docs = d ? d : dataDeepCopy(flow.docs);
    const isLPA = isLPAEnabled(flow);
    const isPP = isPreplanEnabled(flow);
    docs = docs.reduce((acc, doc) => {
      if (doc.type === FLOW_DOC_WILL_TYPE) {
        doc.view = true;
      }
      if (doc.type === FLOW_DOC_LPA_TYPE && isLPA) {
        doc.view = true;
      }
      if (
        (doc.type === FLOW_DOC_PP_LETTER_TYPE ||
          doc.type === FLOW_DOC_PP_TYPE) &&
        isPP
      ) {
        doc.view = true;
      }
      return [...acc, doc];
    }, [] as FlowDocType[]);

    let assets = [];
    let [values, persons] = getPersonsFromGeneralSection([...flow.values]);

    [values, persons] = savePartnerAsHeir(values, persons);
    [values, persons] = getPersonsFromObjectivesSection(values, persons);

    [values, persons, assets] = getDataFromAssetsSection(values, persons);

    [values, persons] = getDataFromLPASection(values, persons);

    [values, persons] = getDataFromPrePlanSection(values, persons);

    dispatch({
      type: "save-whole-flow-data",
      payload: {
        values: values,
        persons: persons,
        assets: assets,
        docs: docs,
        accessToken: user.accessToken,
        sessionUpdateStatus: true,
      },
    });
  };

  const fetchFlowData = async (accessToken: string) => {
    return getUserDataByKey(accessToken, "flow")
      .then((data) => {
        if (!data) {
          return data;
        }

        let dt = JSON.parse(data.value) as FlowType;
        dt = { ...defaultFLow, ...dt };
        setInStorage(flowKey, dt);
        dispatch({ type: "set-from-storage", payload: { flow: dt } });
        return dt;
      })
      .catch((e) => console.log(e));
  };

  const updateDataInSession = () => {
    if (!user.accessToken) {
      return;
    }
    const data = { ...flow };
    data.profile = {
      email: user.profile?.email,
      personalNumber: user.profile?.personNumber,
      firstName: user.profile?.firstName,
      lastName: user.profile?.lastName,
    };

    return updateSession(user.accessToken, flow.session.id, undefined, data);
  };

  const createNewSession = (flowUpd?: FlowType) => {
    if (!user.accessToken) {
      return;
    }
    const tmpFlow = flowUpd ? { ...flowUpd } : { ...flow };
    const data = { ...tmpFlow };
    data.profile = {
      email: user.profile ? user.profile.email : "",
      personalNumber: user.profile ? user.profile.personNumber : "",
      firstName: user.profile ? user.profile.firstName : "",
      lastName: user.profile ? user.profile.lastName : "",
    };
    const userId = user.profile ? user.profile.id : "";
    return createSession(user.accessToken, userId, 1, data).then((dt) => {
      if (dt.id) {
        const obj = { id: dt.id, status: "in progress" };

        if (flowUpd) {
          tmpFlow.session = obj;
          dispatch({
            type: "set-from-storage",
            payload: { flow: tmpFlow },
          });
          pushUserDataToStorage(user.accessToken, tmpFlow);
        } else {
          dispatch({
            type: "set-session",
            payload: { session: obj, accessToken: user.accessToken },
          });
        }

        return dt.id;
      }
    });
  };

  const restartFlow = async (hardReset = false) => {
    if (!user.accessToken) {
      return;
    }
    const action: FlowActionClear = {
      type: "clear",
      payload: {},
    };

    if (!hardReset) {
      action.payload.restarted = true;
    }
    dispatch(action);
    const flowUpd = flowReducer(flow, action);

    await createNewSession(flowUpd)?.finally(() => {
      window.location.href = "/flow";
    });
  };

  const setPackageOrInvitation = (pkg?: number, inv?: FlowInvite) => {
    let pkgId = pkg ? pkg : 3;

    const pk = getFromStorage(partnerKey);
    const partner = getPartnerByUrl(pk);
    if (partner && partner.pkg) {
      pkgId = partner.pkg;
    }

    if (flow?.pkgOvr && config.stage !== "production") {
      pkgId = flow.pkgOvr;
    }

    removeFromStorage(partnerKey);
    const action = {
      type: "set-package-invitation",
      payload: { package: pkgId },
    } as FlowActionType;
    if (inv) {
      action.payload.invitation = inv;
    }
    dispatch(action);

    return flowReducer(flow, action);
  };

  const replaceValuesInTitle = (label?: string) => {
    if (!label) {
      return "";
    }
    function replacer(...args: (string | number)[]) {
      const [match, names] = [...args];
      const namesList = names.toString().split("|");
      if (namesList[0] === "asset-by-person") {
        const personFieldData = flow.values.find(
          (f) => f.name === namesList[1]
        );
        if (!personFieldData) {
          return "";
        }
        const personIDs = personFieldData.value;

        const [v, p, assets] = getDataFromAssetsSection(flow.values, []);
        const propery = assets.filter((a) => a.type === "real-estate");
        if (!propery || !propery.length) {
          return "";
        }

        const propertiesIncludePersons = [] as FlowAsset[];
        propery.map((p) => {
          const fieldData = p.data.find((a) => a.name === namesList[2]);
          const idsInData = personIDs.reduce((acc, id) => {
            if (fieldData && fieldData.value.includes(id)) {
              return [...acc, id];
            }
            return acc;
          }, [] as string[]);
          if (idsInData.length > 0) {
            propertiesIncludePersons.push(p);
          }
        });

        if (!propertiesIncludePersons.length) {
          return "";
        }

        const addresses = propertiesIncludePersons.reduce((acc, p) => {
          const address = p.data.find((d) => d.name === "real-estate-address");
          if (address) {
            return [...acc, address.value.toString()];
          }
          return acc;
        }, [] as string[]);
        const addressesUnique = [...new Set(addresses)];
        return joinArrayWithAnd(addressesUnique);
      }

      const out = namesList.reduce((nAcc, name) => {
        const nameAnswer = flow.values.find((v) => v.name === name);

        if (LPA_PERSON_FIELDS.includes(name) && nameAnswer) {
          const values = nameAnswer.value;
          const pNames = values.reduce((acc, id) => {
            if (id === "other") {
              const dOt = flow.values.find((v) => v.name === `${name}-other`);
              if (dOt) {
                const str = Object.values(dOt.value).reduce((ac, vt) => {
                  const fn = vt.find((f) => f.name === "person-first-name");
                  const ln = vt.find((f) => f.name === "person-last-name");
                  const fname = fn ? fn.value.toString() : "";
                  const lname = ln ? ln.value.toString() : "";
                  return [...ac, `${fname} ${lname}`];
                }, [] as string[]);
                return [...acc, ...str];
              }

              const pfn = flow.values.find(
                (v) => v.name === `${name}-first-name`
              );
              const pln = flow.values.find(
                (v) => v.name === `${name}-last-name`
              );
              if (pfn || pln) {
                const fname = pfn ? pfn.value.toString() : "";
                const lname = pln ? pln.value.toString() : "";
                return [`${fname} ${lname}`];
              }

              return [];
            }
            const p = getPersonList(flow.section).find((pn) => pn.id === id);
            if (!p) {
              return acc;
            }
            if (p.type === "person") {
              return [...acc, `${p.firstName} ${p.lastName}`];
            }
            if (p.type === "org") {
              return [...acc, p.name];
            }
            return acc;
          }, [] as string[]);
          return [...nAcc, ...pNames];
        }

        if (!nameAnswer) {
          return nAcc;
        }

        return [...nAcc, nameAnswer.value.toString()];
      }, [] as string[]);

      const uniqueSet = [...new Set(out)];
      return joinArrayWithAnd(uniqueSet);
    }
    return label.replace(/%([\w\-|]+)%/g, replacer);
  };

  const checkLPAPersonsForValidPersonalNumbers = (p?: FlowPerson[]) => {
    const persons = p ? p : flow.persons;
    const people = LPA_PERSON_FIELDS.reduce((acc, field) => {
      const f = flow.values.find((v) => v.name === field);
      if (!f) {
        return acc;
      }
      return [...acc, ...f.value];
    }, [] as string[]);

    const uniquePeople = [...new Set(people)];
    const peopleData = persons.filter(
      (p) => uniquePeople.includes(p.id) && p.type === "person"
    );

    const validPersons = peopleData.reduce((acc, pd) => {
      if (
        pd.personalNumber &&
        isFieldValid(pd.personalNumber, "personnummer") === true
      ) {
        return [...acc, pd.id];
      }
      return acc;
    }, [] as string[]);

    return validPersons.length === peopleData.length;
  };

  const obj = {
    flow,
    getSetList,
    updatePersonalNumbers,
    fetchFlowData,
    createNewSession,
    updateDataInSession,
    restartFlow,
    setPackageOrInvitation,
    getPersonList,
    replaceValuesInTitle,
    checkLPAPersonsForValidPersonalNumbers,
    saveWholeFlowData,
  };

  return (
    <FlowContext.Provider value={obj}>
      <FlowDispatchContext.Provider value={dispatch}>
        {children}
      </FlowDispatchContext.Provider>
    </FlowContext.Provider>
  );
};

export function useFlowContext() {
  return useContext(FlowContext);
}

export function useFlowDispatchContext() {
  return useContext(FlowDispatchContext);
}
