import config from "../config";
import { AssetType } from "../types/asset";
import {
  FlowFieldOption,
  FlowItem,
  FlowItemField,
  FlowSection,
  FlowSet,
  FlowType,
} from "../types/flow";
import {
  getDiamondIcon,
  getFileThinIcon,
  getHouseIcon,
  getWalletIcon,
} from "./icons";
export const PARTNER_TYPES = ["married", "partner", "apart"];
export const FAMILY_RELATION_OPTIONS = [
  { value: "married", label: "Make/Maka" },
  { value: "partner", label: "Sambo" },
  { value: "child", label: "Barn" },
];
export const RELATION_OPTIONS_WILL = [
  { value: "parents", label: "Förälder" },
  { value: "sibling", label: "Syskon" },
  { value: "nephew", label: "Syskonbarn" },
  { value: "grandparent", label: "Mor- eller farförälder" },
  { value: "maternal_aunt", label: "Moster" },
  { value: "paternal_aunt", label: "Faster" },
  { value: "maternal_uncle", label: "Morbror" },
  { value: "paternal_uncle", label: "Farbror" },
  { value: "cousin", label: "Kusin" },
  { value: "friend", label: "Vän" },
  { value: "colleague", label: "Kollega" },
];
export const RELATION_OPTIONS = [
  ...RELATION_OPTIONS_WILL.slice(0, 3),
  { value: "grandchild", label: "Barnbarn" }, // Insert the "grandchild" after "nephew"
  ...RELATION_OPTIONS_WILL.slice(3),
];
export const ADD_PERSON_ITEMS: FlowItemField[] = [
  {
    label: "Personens namn",
    fields: [
      {
        type: "input",
        name: "person-first-name",
        placeholder: "Förnamn",
        required: true,
        verification: "text",
      },
      {
        type: "input",
        name: "person-last-name",
        placeholder: "Efternamn",
        required: true,
        verification: "text",
      },
    ],
  },
  {
    label: "När är personen född?",
    fields: [
      {
        type: "input",
        name: "person-birthday",
        placeholder: "Personnummer med/utan 4 sista siffrorna",
        required: true,
        verification: "birthday-personnummer",
      },
    ],
  },
  {
    label: "Vad är er relation?",
    fields: [
      {
        type: "select",
        name: "person-relation",
        placeholder: "Välj ett alternativ",
        required: true,
        verification: "text",
        options: RELATION_OPTIONS,
      },
    ],
  },
];
const BASE_ADD_PERSON_ORG_ITEMS: FlowItemField[] = [
  {
    label: "Person eller organisation",
    fields: [
      {
        type: "select",
        name: "type",
        placeholder: "Välj ett alternativ",
        required: true,
        verification: "text",
        options: [
          { label: "Person", value: "person" },
          { label: "Organisation", value: "org" },
        ],
      },
    ],
  },
  {
    label: "Personens namn",
    fields: [
      {
        type: "input",
        name: "person-first-name",
        placeholder: "Förnamn",
        required: true,
        verification: "text",
      },
      {
        type: "input",
        name: "person-last-name",
        placeholder: "Efternamn",
        required: true,
        verification: "text",
      },
    ],
    dependencies: [{ name: "type", values: ["person"] }],
  },
  {
    label: "När är personen född?",
    fields: [
      {
        type: "input",
        name: "person-birthday",
        placeholder: "Personnummer med/utan 4 sista siffrorna",
        required: true,
        verification: "birthday-personnummer",
      },
    ],
    dependencies: [{ name: "type", values: ["person"] }],
  },
  {
    label: "Vad är er relation?",
    fields: [
      {
        type: "select",
        name: "person-relation",
        placeholder: "Välj ett alternativ",
        required: true,
        verification: "text",
        options: [], // This will be customized
      },
    ],
    dependencies: [{ name: "type", values: ["person"] }],
  },
  {
    label: "Organisationens namn",
    fields: [
      {
        type: "input",
        name: "org-name",
        placeholder: "Namn",
        required: true,
        verification: "text",
      },
    ],
    dependencies: [{ name: "type", values: ["org"] }],
  },
  {
    label: "Organisationsnummer",
    fields: [
      {
        type: "input",
        name: "org-number",
        placeholder: "ÅÅMMDD-XXXX",
        required: true,
        verification: "orgnummer",
      },
    ],
    dependencies: [{ name: "type", values: ["org"] }],
  },
];
const createAddPersonOrgItems = (
  relationOptions: { label: string; value: string }[]
): FlowItemField[] => {
  // Clone the base structure and modify the "person-relation" field options
  const items = JSON.parse(JSON.stringify(BASE_ADD_PERSON_ORG_ITEMS));

  const relationField = items
    .flatMap((item) => item.fields)
    .find((field) => field.name === "person-relation");
  if (relationField) {
    relationField.options = relationOptions;
  }

  return items;
};
export const ADD_PERSON_ORG_ITEMS = createAddPersonOrgItems(RELATION_OPTIONS);
export const ADD_PERSON_ORG_ITEMS_WILL = createAddPersonOrgItems(
  RELATION_OPTIONS_WILL
);
export const LPA_PERSON_FIELDS = [
  "lpa-principal-persons",
  "lpa-principal-person-second-hand",
  "lpa-principal-person-third-hand",
  "lpa-property-conflict-person-a",
  "lpa-property-conflict-person-b",
  "lpa-property-conflict-person-c",
  "lpa-audit-persons",
];

export const FLOW_DOC_WILL_ID = 144;
export const FLOW_DOC_WILL_TYPE = "testament";

export const FLOW_DOC_LPA_ID = 145;
export const FLOW_DOC_LPA_TYPE = "lpa";

export const FLOW_DOC_PP_LETTER_ID = 179;
export const FLOW_DOC_PP_LETTER_TYPE = "preplanning-letter";

export const FLOW_DOC_PP_ID = 146;
export const FLOW_DOC_PP_TYPE = "preplanning";

export const FLOW_DOC_IDS = [
  FLOW_DOC_WILL_ID,
  FLOW_DOC_LPA_ID,
  FLOW_DOC_PP_LETTER_ID,
  FLOW_DOC_PP_ID,
];

export const FLOW_SKIP_DATA_CONSENT_PACKAGE_IDS =
  config.stage !== "production" ? [138, 141] : [];

export const FLOW_CONTRACT_IDS = [154, 155, 156, 157, 158, 159];

export const ASSET_TO_PERSON_FIELD = "assets-to-specific-persons";

export const ASSET_PERSONAL_ESTATE_TYPES = [
  { value: "car", label: "Bil" },
  { value: "boat", label: "Båt" },
  { value: "motorbike", label: "Motorcykel / Moped" },
  { value: "jewelry", label: "Smycke" },
  { value: "painting", label: "Tavla" },
  { value: "sculpture", label: "Skulptur" },
  { value: "furniture", label: "Möbel" },
  { value: "other", label: "Annan tillgång" },
];

export const ASSET_SECURITIES_TYPES = [
  { value: "savings-account", label: "Sparkonto" },
  { value: "isk-account", label: "ISK" },
  { value: "securities-account", label: "Fonddepå" },
  { value: "holding-account", label: "Aktiedepå" },
  { value: "endowment-insurance", label: "Kapitalförsäkring" },
  { value: "pension-savings", label: "Pensionssparande" },
  { value: "business", label: "Näringsverksamhet" },
  { value: "other-asset-security", label: "Övrigt" },
];

const QUESTION_WRAPPER_TOP_MARGIN = "mt-[-1.6rem]";

export const flowSets: FlowSet[] = [
  { id: 1, section: 1 },
  { id: 2, section: 1 },
  { id: 1, section: 2 },
  { id: 1, section: 3 },
  {
    id: 2,
    section: 3,
    multiple: "real-estate",
    title: "Fastighet",
    dependencies: [
      {
        type: "value",
        name: "assets-types",
        values: ["real-estate"],
        op: "or",
      },
    ],
  },
  {
    id: 3,
    section: 3,
    multiple: "savings",
    title: "Bankkonto",
    dependencies: [
      {
        type: "value",
        name: "assets-types",
        values: ["savings"],
        op: "or",
      },
    ],
  },
  {
    id: 4,
    section: 3,
    multiple: "personal-estate",
    title: "Lös egendom",
    dependencies: [
      {
        type: "value",
        name: "assets-types",
        values: ["personal-estate"],
        op: "or",
      },
    ],
  },
  {
    id: 5,
    section: 3,
    multiple: "securities",
    title: "Sparande / Investering",
    dependencies: [
      {
        type: "value",
        name: "assets-types",
        values: ["securities"],
        op: "or",
      },
    ],
  },
  { id: 1, section: 4, excludeFromSummary: true },
  { id: 2, section: 4 },
  { id: 1, section: 5 },
  { id: 1, section: 100 },
  { id: 1, section: 101 },
  { id: 1, section: 102 },
];

export const flowSections: FlowSection[] = [
  { id: 1, type: "main", title: "Grundläggande information" },
  { id: 2, type: "main", title: "Önskemål" },
  { id: 3, type: "main", title: "Tillgångar och skulder" },
  { id: 4, type: "main", title: "Framtidsfullmakt" },
  { id: 5, type: "main", title: "Förplanering" },
  { id: 100, type: "will", title: "Färdigställande av Testamente" },
  { id: 101, type: "none", title: "Address check" },
  { id: 102, type: "none", title: "Färdigställande av Framtidsfullmakt" },
];

export const assetsTypes: AssetType[] = [
  {
    type: "real-estate",
    label: "Fastighet",
    description: "Villa, bostadsrätt, fritidshus, tomträtt eller övrigt",
    icon: function () {
      // eslint-disable-next-line prefer-rest-params
      return getHouseIcon(...arguments);
    },
  },
  {
    type: "savings",
    label: "Bankkonto",
    description: "Konton för lön, insättningar och betalningar",
    icon: function () {
      // eslint-disable-next-line prefer-rest-params
      return getWalletIcon(...arguments);
    },
  },
  {
    type: "personal-estate",
    label: "Lös egendom",
    description: "Bil, båt, möbler, konst och annat lösöre",
    icon: function () {
      // eslint-disable-next-line prefer-rest-params
      return getDiamondIcon(...arguments);
    },
  },
  {
    type: "securities",
    label: "Sparande / Investering",
    description: "ISK, fonder, aktier, försäkringar och pensioner",
    icon: function () {
      // eslint-disable-next-line prefer-rest-params
      return getFileThinIcon(...arguments);
    },
  },
];

export const flowData: FlowItem[] = [
  {
    id: "1.1.1",
    section: 1,
    set: 1,
    items: [
      {
        label: "Vad har du för mail?",
        fields: [
          {
            type: "input",
            name: "email",
            placeholder: "Din mailadress",
            verification: "email",
            required: true,
          },
        ],
      },
      {
        label: "Vad har du för telefonnummer?",
        fields: [
          {
            type: "input",
            name: "phone",
            placeholder: "Ditt telefonnummer",
            verification: "phone",
            required: true,
          },
        ],
      },
    ],
    type: "input-group",
  },
  {
    id: "1.1.2",
    section: 1,
    set: 1,
    items: [
      {
        label: "Vad är din civilstatus?",
        fields: [
          {
            type: "select",
            name: "marital-status",
            placeholder: "Välj civilstatus",
            options: [
              { value: "married", label: "Gift" },
              { value: "partner", label: "Sambo" },
              { value: "apart", label: "Särbo" },
              { value: "single", label: "Singel" },
            ],
            required: true,
            verification: "text",
          },
        ],
      },
    ],
  },
  {
    id: "1.1.3",
    section: 1,
    set: 1,
    items: [
      {
        label: "Vad heter din partner?",
        fields: [
          {
            type: "input",
            name: "partner-first-name",
            placeholder: "Förnamn",
            required: true,
            verification: "text",
          },
          {
            type: "input",
            name: "partner-last-name",
            placeholder: "Efternamn",
            required: true,
            verification: "text",
          },
        ],
      },
      {
        label: "När är din partner född?",
        fields: [
          {
            type: "input",
            name: "partner-birthday",
            placeholder: "Personnummer med/utan 4 sista siffrorna",
            required: true,
            verification: "birthday-personnummer",
          },
        ],
      },
    ],
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "marital-status",
        values: ["married", "partner", "apart"],
        op: "or",
      },
    ],
  },
  {
    id: "1.1.4",
    section: 1,
    set: 1,
    items: [
      {
        label: "Är du änka eller änkling?",
        fields: [
          {
            type: "radio",
            name: "widow",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "1.1.5",
    section: 1,
    set: 1,
    items: [
      {
        label: "Har du ärvt din make eller maka med fri förfoganderätt?",
        fields: [
          {
            type: "radio",
            name: "deceased-inherited",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
        info: {
          text: [
            "Om du har ärvt någon med fri förfoganderätt, innebär det att en andel av dina tillgångar utgör efterarv till någon annan den dagen du dör. <br/>Vem eller vilka det är har antingen angivits av den som testamenterade till dig med fri förfoganderätt eller framgår av lag.",
            "Du har inte rätt att testamentera bort den andel som utgör efterarv, men i övrigt har du rätt att fritt bestämma över tillgångarna du fått, och leva som vanligt. Däremot finns det vissa begränsningar när det kommer till att göra sig av med stor andel av egendomen, som till exempel att ge bort stora gåvor.",
          ],
          id: "1",
        },
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "widow",
        values: ["yes"],
        op: "or",
      },
    ],
  },
  {
    id: "1.1.6",
    section: 1,
    set: 1,
    items: [
      {
        label: "Har du ett testamente sedan tidigare?",
        fields: [
          {
            type: "radio",
            name: "have-will",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
  },
  /*  {
    id: "1.1.7",
    section: 1,
    set: 1,
    items: [
      {
        label:
          "Vill du skapa ett nytt testamente som ersätter ditt befintliga?",
        fields: [
          {
            type: "radio",
            name: "new-will",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "have-will",
        values: ["yes"],
        op: "or",
      },
    ],
  },*/
  {
    id: "1.1.8",
    section: 1,
    set: 1,
    items: [
      {
        label:
          "Vill du utse en person som kan ta beslut åt dig när du inte kan ta hand om dig själv eller din ekonomi?",
        fields: [
          {
            type: "radio",
            name: "write-lpa",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "1.1.9",
    section: 1,
    set: 1,
    items: [
      {
        label:
          "Vill du underlätta för dina efterlevande genom att förbereda inför din begravning?",
        fields: [
          {
            type: "radio",
            name: "preplan-funeral",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "1.2.1",
    section: 1,
    set: 2,
    items: [
      {
        label: "Har du barn?",
        fields: [
          {
            type: "radio",
            name: "children",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "1.2.2",
    section: 1,
    set: 2,
    items: [
      {
        label: "",
        fields: [
          {
            type: "checkbox",
            name: "children-desc",
            required: true,
            verification: "checkbox",
            options: [
              {
                value: "current",
                label: "Jag har barn i nuvarande relation",
              },
              { value: "before", label: "Jag har barn från tidigare relation" },
              { value: "bonus", label: "Jag har bonusbarn" },
              { value: "future", label: "Jag ska få barn" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "children",
        values: ["yes"],
        op: "or",
      },
    ],
    nested: { itemId: "1.2.1", fieldName: "children", value: "yes" },
  },
  {
    id: "1.2.5",
    section: 1,
    set: 2,
    items: [
      {
        label: "Barnets namn",
        fields: [
          {
            type: "input",
            name: "first-name",
            placeholder: "Förnamn",
            required: true,
            verification: "text",
          },
          {
            type: "input",
            name: "last-name",
            placeholder: "Efternamn",
            required: true,
            verification: "text",
          },
        ],
      },
      {
        label: "När är barnet fött?",
        fields: [
          {
            type: "input",
            name: "birthday",
            placeholder: "Personnummer med/utan 4 sista siffrorna",
            required: true,
            verification: "birthday-personnummer",
          },
        ],
      },
    ],
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "children-desc",
        values: ["current"],
        op: "or",
      },
    ],
    subsectionTitle: "Barn i nuvarande relation",
    multiple: "children-current",
  },
  {
    id: "1.2.6",
    section: 1,
    set: 2,
    items: [
      {
        label: "Barnets namn",
        fields: [
          {
            type: "input",
            name: "first-name",
            placeholder: "Förnamn",
            required: true,
            verification: "text",
          },
          {
            type: "input",
            name: "last-name",
            placeholder: "Efternamn",
            required: true,
            verification: "text",
          },
        ],
      },
      {
        label: "När är barnet fött?",
        fields: [
          {
            type: "input",
            name: "birthday",
            placeholder: "Personnummer med/utan 4 sista siffrorna",
            required: true,
            verification: "birthday-personnummer",
          },
        ],
      },
    ],
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "children-desc",
        values: ["before"],
        op: "or",
      },
    ],
    subsectionTitle: "Barn i tidigare relation",
    multiple: "children-before",
  },

  {
    id: "1.2.9",
    section: 1,
    set: 2,
    items: [
      {
        label: "Barnets namn",
        fields: [
          {
            type: "input",
            name: "first-name",
            placeholder: "Förnamn",
            required: true,
            verification: "text",
          },
          {
            type: "input",
            name: "last-name",
            placeholder: "Efternamn",
            required: true,
            verification: "text",
          },
        ],
      },
      {
        label: "När är barnet fött?",
        fields: [
          {
            type: "input",
            name: "birthday",
            placeholder: "Personnummer med/utan 4 sista siffrorna",
            required: true,
            verification: "birthday-personnummer",
          },
        ],
      },
    ],
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "children-desc",
        values: ["bonus"],
        op: "or",
      },
    ],
    subsectionTitle: "Bonusbarn",
    multiple: "children-bonus",
  },
  {
    id: "1.2.7",
    section: 1,
    set: 2,
    items: [
      {
        label:
          "Har du någon annan familjemedlem eller vän som ligger dig varmt om hjärtat?",
        fields: [
          {
            type: "radio",
            name: "other-relatives",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "children",
        values: ["no"],
        op: "or",
      },
      {
        type: "value",
        name: "marital-status",
        values: ["single"],
        op: "or",
      },
    ],
  },
  {
    id: "1.2.8",
    section: 1,
    set: 2,
    items: [
      {
        label: "Personens namn",
        fields: [
          {
            type: "input",
            name: "first-name",
            placeholder: "Förnamn",
            required: true,
            verification: "text",
          },
          {
            type: "input",
            name: "last-name",
            placeholder: "Efternamn",
            required: true,
            verification: "text",
          },
        ],
      },
      {
        label: "När är personen född?",
        fields: [
          {
            type: "input",
            name: "birthday",
            placeholder: "Personnummer med/utan 4 sista siffrorna",
            required: true,
            verification: "birthday-personnummer",
          },
        ],
      },
      {
        label: "Vad är er relation?",
        fields: [
          {
            type: "select",
            name: "relation",
            placeholder: "Välj ett alternativ",
            required: true,
            verification: "text",
            options: RELATION_OPTIONS_WILL,
          },
        ],
      },
    ],
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "other-relatives",
        values: ["yes"],
        op: "or",
      },
    ],
    subsectionTitle: "Annan familjemedlem eller vän",
    multiple: "other-relatives-list",
  },

  {
    id: "2.1.1",
    section: 2,
    set: 1,
    items: [
      {
        label:
          "Vill du, efter din bortgång, skydda %partner-first-name% ekonomiskt under %partner-first-name%s livstid?",
        info: {
          text: [
            "Som gift ärver du och din maka/make varandra endast om ni har gemensamma barn eller inga barn alls.",
            "Är du gift men har barn från tidigare relationer kommer dina barn enligt lag ha rätt att få sitt arv direkt vid din bortgång. Du och din make/maka har alltså inte per automatik arvsrätt till varandra i detta fall.",
            "Om du är sambo har det ingen betydelse om ni har gemensamma barn eller inte, eftersom sambor aldrig ärver varandra enligt lag.",
            "Om du vill att din sambo eller make/maka ska ärva dig och på så vis skydda hen ekonomiskt vid din bortgång, kan du välja att testamentera till förmån för hen.",
            "Genom att skriva ett testamente kan du öka möjligheten för din sambo eller make/maka att exempelvis bo kvar i en gemensam bostad.",
          ],
          id: "2",
        },
        fields: [
          {
            type: "radio",
            name: "protect-partner",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "marital-status",
        values: ["partner", "married", "apart"],
        op: "or",
      },
    ],
  },
  {
    id: "2.1.2",
    section: 2,
    set: 1,
    items: [
      {
        label:
          "Vill du att din partner ska dela arvet efter dig med någon annan person eller organisation?",
        info: {
          text: [
            "Om du lever som sambo ärver denne enbart dig om du skriver ett testamente till förmån för din sambo. I det fall du är gift ärver din make/maka dig om ni inte har några barn eller om ni har gemensamma barn. Om du har särkullbarn måste du skriva ett testamente till förmån för din make/maka. Du kan själv välja hur mycket personen du lever med ska ärva och vem som ska erhålla eventuellt återstående arv. Bröstarvingar har dock alltid rätt till sin laglott.",
            "<span class='font-intermedium'>Svarsalternativ 1</span>: Ja",
            "Välj detta alternativ om du vill att make/maka eller sambo ska dela arvet efter dig med någon annan person eller organisation.",
            "<span class='font-intermedium'>Svarsalternativ 2</span>: Nej",
            "Välj detta alternativ om du inte vill utse någon annan person eller organisation förutom din partner.",
          ],
          id: "2.1.2",
        },
        fields: [
          {
            type: "radio",
            name: "partner-share-inheritance",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "protect-partner",
        values: ["yes"],
        op: "or",
      },
    ],
  },
  {
    id: "2.1.2.1",
    section: 2,
    set: 1,
    items: [
      {
        label: "",
        fields: [
          {
            type: "persons-select",
            name: "partner-share-inheritance-persons",
            required: true,
            verification: "checkbox",
            options: [
              {
                value: "other",
                label: "Lägg till utomstående person / organisation",
              },
            ],
            excludePersonRelations: PARTNER_TYPES,
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "partner-share-inheritance",
        values: ["yes"],
        op: "and",
      },
    ],
  },
  {
    id: "2.1.2.2",
    section: 2,
    set: 1,
    items: ADD_PERSON_ORG_ITEMS_WILL,
    multiple: "partner-share-inheritance-persons-other",
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "partner-share-inheritance-persons",
        values: ["other"],
        op: "or",
      },
    ],
  },
  {
    id: "2.1.3",
    section: 2,
    set: 1,
    items: [
      {
        label: "Vem eller vilka ska ärva dig?",
        info: {
          text: [
            "I svensk rätt anges det i Ärvdabalken vilka som ärver dig enligt lag, för det fall du inte har ett testamente.",
            "Genom att upprätta ett testamente kan du själv bestämma vad som ska hända med din kvarlåtenskap den dagen du går bort.",
            "Har du barn eller barnbarn i rakt nedstigande led (bröstarvingar) har de dock alltid rätt att begära sin laglott, oavsett testamente eller inte.",
          ],
          id: "3",
        },
        fields: [
          {
            type: "persons-select",
            name: "heirs",
            required: true,
            verification: "checkbox",
            options: [
              {
                value: "other",
                label: "Lägg till utomstående person / organisation",
              },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "protect-partner",
        values: ["no"],
        op: "and",
      },
    ],
  },
  {
    id: "2.1.3",
    section: 2,
    set: 1,
    items: [
      {
        label: "Vem eller vilka ska ärva dig?",
        info: {
          text: [
            "I svensk rätt anges det i Ärvdabalken vilka som ärver dig enligt lag, för det fall du inte har ett testamente.",
            "Genom att upprätta ett testamente kan du själv bestämma vad som ska hända med din kvarlåtenskap den dagen du går bort.",
            "Har du barn eller barnbarn i rakt nedstigande led (bröstarvingar) har de dock alltid rätt att begära sin laglott, oavsett testamente eller inte.",
          ],
          id: "3",
        },
        fields: [
          {
            type: "persons-select",
            name: "heirs",
            required: true,
            verification: "checkbox",
            options: [
              {
                value: "other",
                label: "Lägg till utomstående person / organisation",
              },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "marital-status",
        values: ["single"],
        op: "or",
      },
    ],
  },
  {
    id: "2.1.4",
    section: 2,
    set: 1,
    items: ADD_PERSON_ORG_ITEMS_WILL,
    multiple: "heirs-other",
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "heirs",
        values: ["other"],
        op: "or",
      },
    ],
  },

  {
    id: "2.1.5",
    section: 2,
    set: 1,
    items: [
      {
        label:
          "Ska arvet fördelas i lika stora andelar mellan personerna du har angivit?",
        info: {
          text: [
            "Du kan helt fritt välja vilka andelar var och en av de du testamenterar till ska erhålla. Vill du fördela arvet i andra proportioner än vad som framgår av lag går det jättebra. Bröstarvingar har dock alltid rätt att begära jämkning av testamentet för att få ut sin laglott ändå, men gör de inte det blir fördelningen som du angett. Mer information om detta längre ner i det här kapitlet.",
            "<span class='font-intermedium'>Svarsalternativ 1</span>: Ja",
            "Välj detta alternativ om du vill att alla dina arvingar ska få lika stora andelar av arvet efter dig. Om du vill testamentera till dina barn, barnbarn, syskonbarn eller liknande och vill att de ska ärva exakt lika mycket är detta ett bra alternativ.",
            "<span class='font-intermedium'>Svarsalternativ 2</span>: Nej",
            "Välj detta alternativ om du vill att dina arvingar ska få olika stora andelar av arvet efter dig. Exempelvis kan du vilja ge 50% till en välgörenhetsorganisation, 30% till en släkting och 20% till din vän.",
          ],
          id: "2.1.2",
        },
        fields: [
          {
            type: "radio",
            name: "inheritance-type",
            required: true,
            verification: "radio",
            options: [
              {
                value: "equal",
                label: "Ja, arvet ska fördelas i lika stora andelar",
              },
              {
                value: "unequal",
                label: "Nej, arvet ska fördelas i olika stora andelar",
              },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value-count",
        name: "heirs",
        values: 2,
        op: ">=",
      },
    ],
  },
  {
    id: "2.1.5",
    section: 2,
    set: 1,
    items: [
      {
        label:
          "Ska arvet fördelas i lika stora andelar mellan personerna du har angivit?",
        info: {
          text: [
            "Du kan helt fritt välja vilka andelar var och en av de du testamenterar till ska erhålla. Vill du fördela arvet i andra proportioner än vad som framgår av lag går det jättebra. Bröstarvingar har dock alltid rätt att begära jämkning av testamentet för att få ut sin laglott ändå, men gör de inte det blir fördelningen som du angett. Mer information om detta längre ner i det här kapitlet.",
            "<span class='font-intermedium'>Svarsalternativ 1</span>: Ja",
            "Välj detta alternativ om du vill att alla dina arvingar ska få lika stora andelar av arvet efter dig. Om du vill testamentera till dina barn, barnbarn, syskonbarn eller liknande och vill att de ska ärva exakt lika mycket är detta ett bra alternativ.",
            "<span class='font-intermedium'>Svarsalternativ 2</span>: Nej",
            "Välj detta alternativ om du vill att dina arvingar ska få olika stora andelar av arvet efter dig. Exempelvis kan du vilja ge 50% till en välgörenhetsorganisation, 30% till en släkting och 20% till din vän.",
          ],
          id: "2.1.2",
        },
        fields: [
          {
            type: "radio",
            name: "inheritance-type",
            required: true,
            verification: "radio",
            options: [
              {
                value: "equal",
                label: "Ja, arvet ska fördelas i lika stora andelar",
              },
              {
                value: "unequal",
                label: "Nej, arvet ska fördelas i olika stora andelar",
              },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "partner-share-inheritance",
        values: ["yes"],
        op: "and",
      },
    ],
  },
  {
    id: "2.1.5.1",
    section: 2,
    set: 1,
    items: [
      {
        label: "Hur ska arvet efter dig fördelas?",
        fields: [
          {
            type: "persons-distribution",
            name: "inheritance-distribution",
            required: true,
            verification: "percentage",
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "inheritance-type",
        values: ["unequal"],
        op: "and",
      },
    ],
  },
  {
    id: "2.1.6",
    section: 2,
    set: 1,
    items: [
      {
        label:
          "Har du önskemål om vem/vilka som ska ärva när %partner-first-name% avlidit?",
        info: {
          text: [
            "Om du testamenterar till din partner med fri förfoganderätt innebär det att din partner inte har rätt att bestämma över den andel som kommer från dig när hen avlider. Istället kommer dina legala arvingar eller de personer du angivit som efterarvingar att ärva.",
            "Arvet som går tillbaka till din sida när din partner avlider kommer att vara en andel och inte ett faktiskt belopp som partner ärvt. Det innebär att det faktiska beloppet både kan öka och minska om det går lång tid mellan dödsfallen.",
            "Om du vill bestämma hur arvet efter dig fördelas när din partner avlidit (efterarvet), välj då mellan svarsalternativen att fördela arvet enligt lag eller att utse en eller flera personer.",
            "Du kan också välja att testamentera med så kallad full äganderätt vilket i så fall innebär att arvet inte går tillbaka till ditt släktled eller några av dig utvalda personer utan istället tillfaller din partners arvingar.",
            "Om du föredrar detta alternativ ska du välja “Nej, jag har inga önskemål”.",
          ],
          id: "4",
        },
        fields: [
          {
            type: "radio",
            name: "inheritance-partner-death",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Arvet ska fördelas enligt lag" },
              {
                value: "list",
                label: "Arvet ska fördelas mellan specifika personer",
              },
              {
                value: "no",
                label:
                  "Nej, jag har inga önskemål, %partner-first-name% får bestämma.",
              },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "protect-partner",
        values: ["yes"],
        op: "and",
      },
    ],
  },
  {
    id: "2.1.6.1",
    section: 2,
    set: 1,
    items: [
      {
        label: "",
        fields: [
          {
            type: "persons-select",
            name: "inheritance-partner-death-persons",
            required: true,
            verification: "checkbox",
            options: [
              {
                value: "other",
                label: "Lägg till utomstående person / organisation",
              },
            ],
            excludePersonRelations: PARTNER_TYPES,
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "inheritance-partner-death",
        values: ["list"],
        op: "and",
      },
    ],
  },
  {
    id: "2.1.6.2",
    section: 2,
    set: 1,
    items: ADD_PERSON_ORG_ITEMS_WILL,
    multiple: "inheritance-partner-death-persons-other",
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "inheritance-partner-death-persons",
        values: ["other"],
        op: "or",
      },
    ],
  },
  {
    id: "2.1.7",
    section: 2,
    set: 1,
    items: [
      {
        label: "Vem/vilka ska ärva om %partner-first-name% avlider före dig?",
        fields: [
          {
            type: "persons-select",
            name: "inheritance-partner-death-before",
            required: true,
            verification: "checkbox",
            options: [
              {
                value: "other",
                label: "Lägg till utomstående person / organisation",
              },
            ],
            excludePersonRelations: PARTNER_TYPES,
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "inheritance-partner-death",
        values: ["no"],
        op: "and",
      },
    ],
  },
  {
    id: "2.1.7.1",
    section: 2,
    set: 1,
    items: ADD_PERSON_ORG_ITEMS_WILL,
    multiple: "inheritance-partner-death-before-other",
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "inheritance-partner-death-before",
        values: ["other"],
        op: "or",
      },
    ],
  },
  {
    id: "2.1.11",
    section: 2,
    set: 1,
    items: [
      {
        label:
          "Vill du att specifika tillgångar ska gå till någon angiven person eller organisation?",
        info: {
          text: [
            "Du kan ge bort specifika saker till utvalda personer om du önskar. Det kan vara allt från ett penningbelopp om exempelvis 50 000 kr, en fonddepå, en bostadsrätt, en bil, smycken, tavlor, instrument, möbler etc. Om det skulle vara så att just den sak du testamenterat till någon inte finns kvar vid din bortgång kommer personen inte få något annat i ersättning. Det är just den saken som kan ärvas av personen du anger, personen kommer alltså inte ärva något likvärdigt.",
            "<span class='font-intermedium'>Svarsalternativ 1</span>: Ja",
            "Om du vill att någon särskild ska ärva en specifik tillgång utöver din fördelning i frågorna ovan ska du svara ja på den här frågan.",
            "<span class='font-intermedium'>Svarsalternativ 2</span>: Nej",
            "Om du inte har någon speciell önskan om att något specifikt ska gå till någon särskild ska du svara nej på den här frågan.",
          ],
          id: "2.1.11",
        },
        fields: [
          {
            type: "radio",
            name: ASSET_TO_PERSON_FIELD,
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "2.1.8",
    section: 2,
    set: 1,
    items: [
      {
        label:
          "Om din/dina arvtagare skiljer sig eller separerar, vill du att de ska behålla arvet efter dig?",
        info: {
          text: [
            "Att ärva något som enskild egendom betyder att arvet inte kommer att ingå i en framtida bodelning om man skiljer sig eller separerar.",
            "Den som ärver med villkor om enskild egendom behöver med andra ord inte dela med sig av arvet med en partner vid en eventuell skilsmässa eller separation och bodelning.",
          ],
          id: "5",
        },
        fields: [
          {
            type: "radio",
            name: "received-as-private-property",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "2.1.9",
    section: 2,
    set: 1,
    items: [
      {
        label:
          "Vill du att ditt/dina barn automatiskt ska få ut sin laglott som de enligt lag har rätt till?",
        info: {
          text: [
            "När det kommer till bröstarvingar brukar man prata om arvslott och laglott. Arvslotten utgör bröstarvinges totala arv (den del som följer enligt lag). Laglotten utgör hälften av arvslotten.",
            "Genom att upprätta ett testamente kan man begränsa en bröstarvinges arv till att endast omfatta laglotten.",
            "Även om din bröstarvinge alltid har en juridisk rätt att begära sin laglott, kan du i ditt testamente framföra att du inte vill att laglotten ska utgå.",
            "Skriver du ett testamente med önskemål att laglotten inte ska utgå blir det giltigt om det godkänns av dina bröstarvingar efter att du har avlidit.",
            "Om dina bröstarvingar vill ha ut sin laglott direkt när du avlider trots att du har angivit att din önskar är att de inte ska få det behöver de begära jämkning av testamentet för att få ut sin laglott. Begäran om jämkning behöver ske inom sex månader från det att bröstarvingen har blivit delgiven testamentet.",
          ],
          id: "6",
        },
        fields: [
          {
            type: "radio",
            name: "children-inheritance-automatically",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "children-desc",
        values: ["before", "current"],
        op: "or",
      },
    ],
  },
  {
    id: "2.1.10",
    section: 2,
    set: 1,
    items: [
      {
        label: "Vill du att Fenix Family AB verkställer ditt testamente?",
        info: {
          text: [
            "Du kan i ditt testamente bestämma vem som ska ansvara för förvaltningen och avvecklingen av ditt dödsbo samt se till att önskemålen i testamentet blir verklighet.",
            "Det är lämpligt att en jurist som är kunnig inom familjerätt utses till testamentsexekutor. Önskar du att Fenix Family AB ska verkställa ditt testamente så åtar vi oss gärna den uppgiften. Kostnaden för testamentsexekutorn betalas av dödsboet.",
          ],
          id: "7",
        },
        fields: [
          {
            type: "radio",
            name: "fenix-as-executor",
            required: true,
            verification: "radio",
            options: [
              {
                value: "yes",
                label: "Ja",
              },
              {
                value: "no",
                label: "Nej",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    id: "3.1.1",
    section: 3,
    set: 1,
    items: [
      {
        label: "Har du några tillgångar?",
        fields: [
          {
            type: "radio",
            name: "assets",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "3.1.2",
    section: 3,
    set: 1,
    items: [
      {
        label: "",
        fields: [
          {
            type: "assets-select",
            name: "assets-types",
            required: true,
            verification: "checkbox",
            options: assetsTypes.reduce((acc, type) => {
              return [
                ...acc,
                {
                  value: type.type,
                  label: type.label,
                  description: type.description,
                  icon: type.icon,
                },
              ];
            }, [] as FlowFieldOption[]),
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "assets",
        values: ["yes"],
        op: "or",
      },
    ],
    nested: { itemId: "3.1.1", fieldName: "assets", value: "yes" },
  },

  {
    id: "3.2.0",
    section: 3,
    set: 2,
    items: [
      {
        label: "Vad är det du äger?",
        fields: [
          {
            type: "select",
            name: "lpa-property-type",
            placeholder: "Välj ett alternativ",
            required: true,
            verification: "text",
            options: [
              { value: "real-estate", label: "Villa" },
              { value: "right-of-residence", label: "Bostadsrätt" },
              { value: "vacation-house", label: "Fritidshus" },
              { value: "land-right", label: "Tomträtt" },
              { value: "real-estate-other", label: "Övrigt" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "assets-types",
        values: ["real-estate"],
        op: "or",
      },
    ],
    subsectionTitle: "Definiera fastigheter",
  },
  {
    id: "3.2.1.2",
    section: 3,
    set: 2,
    items: [
      {
        label: 'Bostadsrättsföreningens namn (till exempel "BRF Berget 28")',
        fields: [
          {
            type: "input",
            name: "real-estate-condominium-association",
            placeholder: "Ange bostadsrättsföreningens namn",
            verification: "text",
            required: true,
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "lpa-property-type",
        values: ["right-of-residence"],
        op: "or",
      },
    ],
  },
  {
    id: "3.2.1.3",
    section: 3,
    set: 2,
    items: [
      {
        label: 'Bostadsrättens lägenhetsnummer (till exempel "Lgh 0025")',
        fields: [
          {
            type: "input",
            name: "real-estate-apartment-number",
            placeholder: "Ange bostadsrättens lägenhetsnummer",
            verification: "text",
            required: true,
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "lpa-property-type",
        values: ["right-of-residence"],
        op: "or",
      },
    ],
  },
  {
    id: "3.2.1.4",
    section: 3,
    set: 2,
    items: [
      {
        label:
          'Ange fastighetsbeteckning (kommunnamn traktnamn blocknummer:enhetsnummer, till exempel "Gävle Olsbacka 11:1")',
        fields: [
          {
            type: "input",
            name: "real-estate-property-designation",
            placeholder: "Ange fastighetsbeteckning",
            verification: "property-designation",
            required: true,
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "lpa-property-type",
        values: ["real-estate", "vacation-house"],
        op: "or",
      },
    ],
  },
  {
    id: "3.2.1.5",
    section: 3,
    set: 2,
    items: [
      {
        label: "Beskriv fastigheten",
        fields: [
          {
            type: "input",
            name: "real-estate-description",
            placeholder: "Ange beskrivning av fastigheten",
            verification: "text",
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "lpa-property-type",
        values: ["land-right", "real-estate-other"],
        op: "or",
      },
    ],
  },
  {
    id: "3.2.1.1",
    section: 3,
    set: 2,
    items: [
      {
        label: "Ange fullständig adress",
        fields: [
          {
            type: "input",
            name: "real-estate-address",
            placeholder: "Ange adress",
            verification: "text",
            required: true,
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "assets-types",
        values: ["real-estate"],
        op: "or",
      },
    ],
  },
  {
    id: "3.2.2",
    section: 3,
    set: 2,
    items: [
      {
        label: "Uppskattat nuvarande värde?",
        fields: [
          {
            type: "input",
            name: "real-estate-price",
            placeholder: "Ange belopp (endast siffror)",
            verification: "text",
            required: true,
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "assets-types",
        values: ["real-estate"],
        op: "or",
      },
    ],
  },
  {
    id: "3.2.3",
    section: 3,
    set: 2,
    items: [
      {
        label: "Är fastigheten enskild egendom?",
        fields: [
          {
            type: "radio",
            name: "asset-private",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
        info: {
          text: [
            "Enskild egendom är motsatsen till giftorättsgods. Enskild egendom ingår inte i en bodelning mellan makar eller sambor. Det finns tre olika sätt att göra egendom till enskild:",
            "• Makar eller blivande makar kan genom ett äktenskapsförord avtala att viss egendom eller all egendom ska vara enskild egendom.",
            "• Vid arv kan arvlåtaren i ett testamente ha bestämt att arvet ska vara mottagarens enskilda egendom.",
            "• Vid en gåva från någon annan än den andra maken kan givaren i en gåvohandling ha bestämt att egendomen som personen får ska vara mottagarens enskilda egendom.",
            "Utgångspunkten är att avkastning av enskild egendom är giftorättsgods medan substitut, d.v.s. egendom som ersätter den enskilda egendomen, som utgångspunkt också är enskild. Detta kan dock ändras genom att annat avtalas i äktenskapsförord eller bestäms i testamente eller gåvobrev.",
          ],
          id: "321",
        },
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "assets-types",
        values: ["real-estate"],
        op: "or",
      },
    ],
  },
  {
    id: "3.2.4",
    section: 3,
    set: 2,
    items: [
      {
        label: "Äger du fastigheten tillsammans med någon?",
        fields: [
          {
            type: "radio",
            name: "asset-owned-with-partner",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "assets-types",
        values: ["real-estate"],
        op: "or",
      },
    ],
  },
  {
    id: "3.2.5",
    section: 3,
    set: 2,
    items: [
      {
        label: "",
        fields: [
          {
            type: "persons-select",
            name: "asset-owned-persons",
            required: true,
            verification: "checkbox",
            options: [
              {
                value: "other",
                label: "Lägg till utomstående person",
              },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "asset-owned-with-partner",
        values: ["yes"],
        op: "or",
      },
    ],
  },
  {
    id: "3.2.6",
    section: 3,
    set: 2,
    items: ADD_PERSON_ITEMS,
    multiple: "asset-owned-persons-other",
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "asset-owned-persons",
        values: ["other"],
        op: "or",
      },
    ],
  },
  {
    id: "3.2.6.1",
    section: 3,
    set: 2,
    items: [
      {
        label: "Hur stor är din del av tillgången?",
        fields: [
          {
            type: "number",
            name: "asset-owned-with-partner-share",
            required: true,
            verification: "percentage",
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "asset-owned-with-partner",
        values: ["yes"],
        op: "or",
      },
    ],
  },
  {
    id: "3.2.7",
    section: 3,
    set: 2,
    items: [
      {
        label: "Är din tillgång belånad?",
        fields: [
          {
            type: "radio",
            name: "asset-debt",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "assets-types",
        values: ["real-estate"],
        op: "or",
      },
    ],
  },
  {
    id: "3.2.8",
    section: 3,
    set: 2,
    items: [
      {
        label: "",
        fields: [
          {
            type: "checkbox",
            name: "asset-debt-type",
            required: true,
            verification: "checkbox",
            options: [
              { value: "bank", label: "Ja, banklån" },
              {
                value: "personal",
                label: "Ja, personligt lån",
              },
              {
                value: "other",
                label: "Ja, annat lån",
              },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "asset-debt",
        values: ["yes"],
        op: "or",
      },
    ],
    nested: {
      itemId: "3.2.7",
      fieldName: "asset-debt",
      value: "yes",
    },
  },
  {
    id: "3.2.9",
    section: 3,
    set: 2,
    items: [
      {
        label: "Ungefärlig storlek på din del av lånet?",
        fields: [
          {
            type: "input",
            name: "asset-debt-amount",
            placeholder: "Ange belopp (endast siffror)",
            required: true,
            verification: "text",
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "asset-debt",
        values: ["yes"],
        op: "or",
      },
    ],
    subsectionTitle: "Delge din andel av lån",
  },
  {
    id: "3.2.10",
    section: 3,
    set: 2,
    items: [
      {
        label:
          "Vill du testamentera tillgången till någon specifik person eller organisation?",
        fields: [
          {
            type: "radio",
            name: "bequeath-asset",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: ASSET_TO_PERSON_FIELD,
        values: ["yes"],
        op: "or",
      },
    ],
  },
  {
    id: "3.2.11.1",
    section: 3,
    set: 2,
    items: [
      {
        label: "Välj vem eller vilka som ska ärva tillgången",
        fields: [
          {
            type: "persons-select",
            name: "asset-bequeath-persons",
            required: true,
            verification: "checkbox",
            options: [
              {
                value: "other",
                label: "Lägg till utomstående person",
              },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "bequeath-asset",
        values: ["yes"],
        op: "or",
      },
    ],
  },
  {
    id: "3.2.11.2",
    section: 3,
    set: 2,
    items: ADD_PERSON_ORG_ITEMS,
    multiple: "asset-bequeath-persons-other",
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "asset-bequeath-persons",
        values: ["other"],
        op: "or",
      },
    ],
  },
  {
    id: "3.2.11.3",
    section: 3,
    set: 2,
    items: [
      {
        label:
          "Ska arvet fördelas i lika stora andelar mellan personerna du har angivit?",
        info: {
          text: [
            "Du kan helt fritt välja vilka andelar var och en av de du testamenterar till ska erhålla. Vill du fördela arvet i andra proportioner än vad som framgår av lag går det jättebra. Bröstarvingar har dock alltid rätt att begära jämkning av testamentet för att få ut sin laglott ändå, men gör de inte det blir fördelningen som du angett. Mer information om detta längre ner i det här kapitlet.",
            "<span class='font-intermedium'>Svarsalternativ 1</span>: Ja",
            "Välj detta alternativ om du vill att alla dina arvingar ska få lika stora andelar av arvet efter dig. Om du vill testamentera till dina barn, barnbarn, syskonbarn eller liknande och vill att de ska ärva exakt lika mycket är detta ett bra alternativ.",
            "<span class='font-intermedium'>Svarsalternativ 2</span>: Nej",
            "Välj detta alternativ om du vill att dina arvingar ska få olika stora andelar av arvet efter dig. Exempelvis kan du vilja ge 50% till en välgörenhetsorganisation, 30% till en släkting och 20% till din vän.",
          ],
          id: "3.2.11.3",
        },
        fields: [
          {
            type: "radio",
            name: "asset-inheritance-type",
            required: true,
            verification: "radio",
            options: [
              {
                value: "equal",
                label: "Ja, arvet ska fördelas i lika stora andelar",
              },
              {
                value: "unequal",
                label: "Nej, arvet ska fördelas i olika stora andelar",
              },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value-count",
        name: "asset-bequeath-persons",
        values: 2,
        op: ">=",
      },
    ],
  },
  {
    id: "3.2.11.4",
    section: 3,
    set: 2,
    items: [
      {
        label: "Hur ska arvet efter dig fördelas?",
        fields: [
          {
            type: "persons-distribution-asset",
            name: "asset-inheritance-distribution",
            required: true,
            verification: "percentage",
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "asset-inheritance-type",
        values: ["unequal"],
        op: "and",
      },
    ],
  },

  {
    id: "3.3.1",
    section: 3,
    set: 3,
    items: [
      {
        label: "Ange bank, clearingnummer, kontonummer",
        fields: [
          {
            type: "input",
            name: "savings-description",
            placeholder: "Bank, clearingnummer, kontonummer",
            verification: "text",
            required: true,
          },
        ],
      },
      {
        label: "Uppskattat nuvarande värde?",
        fields: [
          {
            type: "input",
            name: "savings-value",
            placeholder: "Ange belopp (endast siffror)",
            verification: "text",
            required: true,
          },
        ],
      },
    ],
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "assets-types",
        values: ["savings"],
        op: "or",
      },
    ],
  },
  {
    id: "3.3.2",
    section: 3,
    set: 3,
    items: [
      {
        label: "Är tillgången enskild egendom?",
        fields: [
          {
            type: "radio",
            name: "asset-private",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
        info: {
          text: [
            "Enskild egendom är motsatsen till giftorättsgods. Enskild egendom ingår inte i en bodelning mellan makar eller sambor. Det finns tre olika sätt att göra egendom till enskild:",
            "• Makar eller blivande makar kan genom ett äktenskapsförord avtala att viss egendom eller all egendom ska vara enskild egendom.",
            "• Vid arv kan arvlåtaren i ett testamente ha bestämt att arvet ska vara mottagarens enskilda egendom.",
            "• Vid en gåva från någon annan än den andra maken kan givaren i en gåvohandling ha bestämt att egendomen som personen får ska vara mottagarens enskilda egendom.",
            "Utgångspunkten är att avkastning av enskild egendom är giftorättsgods medan substitut, d.v.s. egendom som ersätter den enskilda egendomen, som utgångspunkt också är enskild. Detta kan dock ändras genom att annat avtalas i äktenskapsförord eller bestäms i testamente eller gåvobrev.",
          ],
          id: "331",
        },
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "assets-types",
        values: ["savings"],
        op: "or",
      },
    ],
  },
  {
    id: "3.3.3",
    section: 3,
    set: 3,
    items: [
      {
        label: "Äger du tillgången tillsammans med någon?",
        fields: [
          {
            type: "radio",
            name: "asset-owned-with-partner",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "assets-types",
        values: ["savings"],
        op: "or",
      },
    ],
  },
  {
    id: "3.3.5",
    section: 3,
    set: 3,
    items: [
      {
        label: "",
        fields: [
          {
            type: "persons-select",
            name: "asset-owned-persons",
            required: true,
            verification: "checkbox",
            options: [
              {
                value: "other",
                label: "Lägg till utomstående person",
              },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "asset-owned-with-partner",
        values: ["yes"],
        op: "or",
      },
    ],
  },
  {
    id: "3.3.6",
    section: 3,
    set: 3,
    items: ADD_PERSON_ITEMS,
    multiple: "asset-owned-persons-other",
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "asset-owned-persons",
        values: ["other"],
        op: "or",
      },
    ],
  },
  {
    id: "3.3.7",
    section: 3,
    set: 3,
    items: [
      {
        label: "Hur stor är din del av tillgången?",
        fields: [
          {
            type: "number",
            name: "asset-owned-with-partner-share",
            required: true,
            verification: "percentage",
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "asset-owned-with-partner",
        values: ["yes"],
        op: "or",
      },
    ],
  },
  {
    id: "3.3.10",
    section: 3,
    set: 3,
    items: [
      {
        label:
          "Vill du testamentera tillgången till någon specifik person eller organisation?",
        fields: [
          {
            type: "radio",
            name: "bequeath-asset",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: ASSET_TO_PERSON_FIELD,
        values: ["yes"],
        op: "or",
      },
    ],
  },
  {
    id: "3.3.11.1",
    section: 3,
    set: 3,
    items: [
      {
        label: "Välj vem eller vilka som ska ärva tillgången",
        fields: [
          {
            type: "persons-select",
            name: "asset-bequeath-persons",
            required: true,
            verification: "checkbox",
            options: [
              {
                value: "other",
                label: "Lägg till utomstående person",
              },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "bequeath-asset",
        values: ["yes"],
        op: "or",
      },
    ],
  },
  {
    id: "3.3.11.2",
    section: 3,
    set: 3,
    items: ADD_PERSON_ORG_ITEMS,
    multiple: "asset-bequeath-persons-other",
    multipleLimit: 1,
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "asset-bequeath-persons",
        values: ["other"],
        op: "or",
      },
    ],
  },
  {
    id: "3.3.11.3",
    section: 3,
    set: 3,
    items: [
      {
        label:
          "Ska arvet fördelas i lika stora andelar mellan personerna du har angivit?",
        info: {
          text: [
            "Du kan helt fritt välja vilka andelar var och en av de du testamenterar till ska erhålla. Vill du fördela arvet i andra proportioner än vad som framgår av lag går det jättebra. Bröstarvingar har dock alltid rätt att begära jämkning av testamentet för att få ut sin laglott ändå, men gör de inte det blir fördelningen som du angett. Mer information om detta längre ner i det här kapitlet.",
            "<span class='font-intermedium'>Svarsalternativ 1</span>: Ja",
            "Välj detta alternativ om du vill att alla dina arvingar ska få lika stora andelar av arvet efter dig. Om du vill testamentera till dina barn, barnbarn, syskonbarn eller liknande och vill att de ska ärva exakt lika mycket är detta ett bra alternativ.",
            "<span class='font-intermedium'>Svarsalternativ 2</span>: Nej",
            "Välj detta alternativ om du vill att dina arvingar ska få olika stora andelar av arvet efter dig. Exempelvis kan du vilja ge 50% till en välgörenhetsorganisation, 30% till en släkting och 20% till din vän.",
          ],
          id: "3.3.11.3",
        },
        fields: [
          {
            type: "radio",
            name: "asset-inheritance-type",
            required: true,
            verification: "radio",
            options: [
              {
                value: "equal",
                label: "Ja, arvet ska fördelas i lika stora andelar",
              },
              {
                value: "unequal",
                label: "Nej, arvet ska fördelas i olika stora andelar",
              },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value-count",
        name: "asset-bequeath-persons",
        values: 2,
        op: ">=",
      },
    ],
  },
  {
    id: "3.3.11.4",
    section: 3,
    set: 3,
    items: [
      {
        label: "Hur ska arvet efter dig fördelas?",
        fields: [
          {
            type: "persons-distribution-asset",
            name: "asset-inheritance-distribution",
            required: true,
            verification: "percentage",
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "asset-inheritance-type",
        values: ["unequal"],
        op: "and",
      },
    ],
  },
  {
    id: "3.4.1",
    section: 3,
    set: 4,
    items: [
      {
        label: "Vilken typ av egendom har du?",
        fields: [
          {
            type: "select",
            name: "personal-estate-type",
            placeholder: "Ange typ",
            verification: "text",
            required: true,
            options: ASSET_PERSONAL_ESTATE_TYPES,
          },
        ],
      },
      {
        label: "Modell / Märke?",
        fields: [
          {
            type: "input",
            name: "personal-estate-make",
            placeholder: "Ange modell eller märke",
            verification: "text",
            required: true,
          },
        ],
      },
      {
        label: "Årgång?",
        fields: [
          {
            type: "input",
            name: "personal-estate-year",
            placeholder: "Ange årgång",
            verification: "text",
            required: true,
          },
        ],
      },
      {
        label: "Skick?",
        fields: [
          {
            type: "input",
            name: "personal-estate-condition",
            placeholder: "Ange skick",
            verification: "text",
            required: true,
          },
        ],
      },
      {
        label: "Övrig information",
        info: {
          text: [
            "Det är viktigt för dina arvingar att veta vad du menar när du nämner en viss tillgång. Ange därför kortfattad men tydlig information om varje sak du vill att någon ska ärva.",
            "Om du exempelvis vill testamentera bort en tavla; ange vem konstnären är, vilket motiv tavlan har, var i bostaden tavlan hänger, ungefärlig storlek och liknande.",
          ],
          id: "341",
        },
        fields: [
          {
            type: "input",
            name: "personal-estate-value-description",
            placeholder: "",
            verification: "text",
            required: false,
          },
        ],
      },
      {
        label: "Uppskattat nuvarande värde?",
        fields: [
          {
            type: "input",
            name: "personal-estate-value",
            placeholder: "Ange belopp (endast siffror)",
            verification: "text",
            required: true,
          },
        ],
      },
    ],
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "assets-types",
        values: ["personal-estate"],
        op: "or",
      },
    ],
  },
  {
    id: "3.4.2",
    section: 3,
    set: 4,
    items: [
      {
        label: "Är tillgången enskild egendom?",
        fields: [
          {
            type: "radio",
            name: "asset-private",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
        info: {
          text: [
            "Enskild egendom är motsatsen till giftorättsgods. Enskild egendom ingår inte i en bodelning mellan makar eller sambor. Det finns tre olika sätt att göra egendom till enskild:",
            "• Makar eller blivande makar kan genom ett äktenskapsförord avtala att viss egendom eller all egendom ska vara enskild egendom.",
            "• Vid arv kan arvlåtaren i ett testamente ha bestämt att arvet ska vara mottagarens enskilda egendom.",
            "• Vid en gåva från någon annan än den andra maken kan givaren i en gåvohandling ha bestämt att egendomen som personen får ska vara mottagarens enskilda egendom.",
            "Utgångspunkten är att avkastning av enskild egendom är giftorättsgods medan substitut, d.v.s. egendom som ersätter den enskilda egendomen, som utgångspunkt också är enskild. Detta kan dock ändras genom att annat avtalas i äktenskapsförord eller bestäms i testamente eller gåvobrev.",
          ],
          id: "341",
        },
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "assets-types",
        values: ["personal-estate"],
        op: "or",
      },
    ],
  },
  {
    id: "3.4.3",
    section: 3,
    set: 4,
    items: [
      {
        label: "Äger du tillgången tillsammans med någon?",
        fields: [
          {
            type: "radio",
            name: "asset-owned-with-partner",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "assets-types",
        values: ["personal-estate"],
        op: "or",
      },
    ],
  },
  {
    id: "3.4.5",
    section: 3,
    set: 4,
    items: [
      {
        label: "",
        fields: [
          {
            type: "persons-select",
            name: "asset-owned-persons",
            required: true,
            verification: "checkbox",
            options: [
              {
                value: "other",
                label: "Lägg till utomstående person",
              },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "asset-owned-with-partner",
        values: ["yes"],
        op: "or",
      },
    ],
  },
  {
    id: "3.4.6",
    section: 3,
    set: 4,
    items: ADD_PERSON_ITEMS,
    multiple: "asset-owned-persons-other",
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "asset-owned-persons",
        values: ["other"],
        op: "or",
      },
    ],
  },
  {
    id: "3.4.7",
    section: 3,
    set: 4,
    items: [
      {
        label: "Hur stor är din del av tillgången?",
        fields: [
          {
            type: "number",
            name: "asset-owned-with-partner-share",
            required: true,
            verification: "percentage",
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "asset-owned-with-partner",
        values: ["yes"],
        op: "or",
      },
    ],
  },
  {
    id: "3.4.8",
    section: 3,
    set: 4,
    items: [
      {
        label: "Är din tillgång belånad?",
        fields: [
          {
            type: "radio",
            name: "asset-debt",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "assets-types",
        values: ["personal-estate"],
        op: "or",
      },
    ],
  },
  {
    id: "3.4.9",
    section: 3,
    set: 4,
    items: [
      {
        label: "",
        fields: [
          {
            type: "checkbox",
            name: "asset-debt-type",
            required: true,
            verification: "checkbox",
            options: [
              { value: "bank", label: "Ja, banklån" },
              {
                value: "personal",
                label: "Ja, personligt lån",
              },
              {
                value: "other",
                label: "Ja, annat lån",
              },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "asset-debt",
        values: ["yes"],
        op: "or",
      },
    ],
    nested: {
      itemId: "3.4.8",
      fieldName: "asset-debt",
      value: "yes",
    },
  },
  {
    id: "3.4.9.1",
    section: 3,
    set: 4,
    items: [
      {
        label: "Ungefärlig storlek på din del av lånet?",
        fields: [
          {
            type: "input",
            name: "asset-debt-amount",
            placeholder: "Ange belopp (endast siffror)",
            required: true,
            verification: "text",
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "asset-debt",
        values: ["yes"],
        op: "or",
      },
    ],
    subsectionTitle: "Delge din andel av lån",
  },
  {
    id: "3.4.10",
    section: 3,
    set: 4,
    items: [
      {
        label:
          "Vill du testamentera tillgången till någon specifik person eller organisation?",
        fields: [
          {
            type: "radio",
            name: "bequeath-asset",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: ASSET_TO_PERSON_FIELD,
        values: ["yes"],
        op: "or",
      },
    ],
  },
  {
    id: "3.4.11.1",
    section: 3,
    set: 4,
    items: [
      {
        label: "Välj vem eller vilka som ska ärva tillgången",
        fields: [
          {
            type: "persons-select",
            name: "asset-bequeath-persons",
            required: true,
            verification: "checkbox",
            options: [
              {
                value: "other",
                label: "Lägg till utomstående person",
              },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "bequeath-asset",
        values: ["yes"],
        op: "or",
      },
    ],
  },
  {
    id: "3.4.11.2",
    section: 3,
    set: 4,
    items: ADD_PERSON_ORG_ITEMS,
    multiple: "asset-bequeath-persons-other",
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "asset-bequeath-persons",
        values: ["other"],
        op: "or",
      },
    ],
  },
  {
    id: "3.4.11.3",
    section: 3,
    set: 4,
    items: [
      {
        label:
          "Ska arvet fördelas i lika stora andelar mellan personerna du har angivit?",
        info: {
          text: [
            "Du kan helt fritt välja vilka andelar var och en av de du testamenterar till ska erhålla. Vill du fördela arvet i andra proportioner än vad som framgår av lag går det jättebra. Bröstarvingar har dock alltid rätt att begära jämkning av testamentet för att få ut sin laglott ändå, men gör de inte det blir fördelningen som du angett. Mer information om detta längre ner i det här kapitlet.",
            "<span class='font-intermedium'>Svarsalternativ 1</span>: Ja",
            "Välj detta alternativ om du vill att alla dina arvingar ska få lika stora andelar av arvet efter dig. Om du vill testamentera till dina barn, barnbarn, syskonbarn eller liknande och vill att de ska ärva exakt lika mycket är detta ett bra alternativ.",
            "<span class='font-intermedium'>Svarsalternativ 2</span>: Nej",
            "Välj detta alternativ om du vill att dina arvingar ska få olika stora andelar av arvet efter dig. Exempelvis kan du vilja ge 50% till en välgörenhetsorganisation, 30% till en släkting och 20% till din vän.",
          ],
          id: "3.4.11.3",
        },
        fields: [
          {
            type: "radio",
            name: "asset-inheritance-type",
            required: true,
            verification: "radio",
            options: [
              {
                value: "equal",
                label: "Ja, arvet ska fördelas i lika stora andelar",
              },
              {
                value: "unequal",
                label: "Nej, arvet ska fördelas i olika stora andelar",
              },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value-count",
        name: "asset-bequeath-persons",
        values: 2,
        op: ">=",
      },
    ],
  },
  {
    id: "3.4.11.4",
    section: 3,
    set: 4,
    items: [
      {
        label: "Hur ska arvet efter dig fördelas?",
        fields: [
          {
            type: "persons-distribution-asset",
            name: "asset-inheritance-distribution",
            required: true,
            verification: "percentage",
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "asset-inheritance-type",
        values: ["unequal"],
        op: "and",
      },
    ],
  },

  {
    id: "3.5.1",
    section: 3,
    set: 5,
    items: [
      {
        label: "Vilken form av sparande eller investering har du?",
        fields: [
          {
            type: "select",
            name: "securities-type",
            placeholder: "Ange typ",
            verification: "text",
            required: true,
            options: ASSET_SECURITIES_TYPES,
          },
        ],
      },
      {
        label: "Ange var du har tillgången samt konto/depå/försäkringsnummer",
        info: {
          text: [
            'Om du vill ge värdepapper till någon måste dessa kunna identifieras. Ange information om exakt antal aktier du vill ge bort och vad för typ av aktier, exempelvis: "X st. aktier i Xxxxxx xxxx" eller "Samtliga mina fonder i Xxxxxxxx".',
            'Vill du istället ge alla olika typer av aktier som finns i depå eller fonder på ett fondkonto, ange då nummer på depå eller fondkonto, exempelvis: "Hela mitt aktieinnehav i depå xxxxxx-xxxx i bank xxxx" eller "Alla mina fonder på konto nr xxxxxxx i bank xxxxx".',
          ],
          id: "3513",
        },
        fields: [
          {
            type: "input",
            name: "asset-bequeath-description",
            placeholder: "",
            verification: "text",
            required: true,
          },
        ],
      },
      {
        label: "Uppskattat nuvarande värde?",
        fields: [
          {
            type: "input",
            name: "securities-value",
            placeholder: "Ange belopp (endast siffror)",
            verification: "text",
            required: true,
          },
        ],
      },
    ],
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "assets-types",
        values: ["securities"],
        op: "or",
      },
    ],
  },
  {
    id: "3.5.2",
    section: 3,
    set: 5,
    items: [
      {
        label: "Är tillgången enskild egendom?",
        fields: [
          {
            type: "radio",
            name: "asset-private",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
        info: {
          text: [
            "Enskild egendom är motsatsen till giftorättsgods. Enskild egendom ingår inte i en bodelning mellan makar eller sambor. Det finns tre olika sätt att göra egendom till enskild:",
            "• Makar eller blivande makar kan genom ett äktenskapsförord avtala att viss egendom eller all egendom ska vara enskild egendom.",
            "• Vid arv kan arvlåtaren i ett testamente ha bestämt att arvet ska vara mottagarens enskilda egendom.",
            "• Vid en gåva från någon annan än den andra maken kan givaren i en gåvohandling ha bestämt att egendomen som personen får ska vara mottagarens enskilda egendom.",
            "Utgångspunkten är att avkastning av enskild egendom är giftorättsgods medan substitut, d.v.s. egendom som ersätter den enskilda egendomen, som utgångspunkt också är enskild. Detta kan dock ändras genom att annat avtalas i äktenskapsförord eller bestäms i testamente eller gåvobrev.",
          ],
          id: "351",
        },
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "assets-types",
        values: ["securities"],
        op: "or",
      },
    ],
  },
  {
    id: "3.5.3",
    section: 3,
    set: 5,
    items: [
      {
        label: "Äger du tillgången tillsammans med någon?",
        fields: [
          {
            type: "radio",
            name: "asset-owned-with-partner",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "assets-types",
        values: ["securities"],
        op: "or",
      },
    ],
  },
  {
    id: "3.5.5",
    section: 3,
    set: 5,
    items: [
      {
        label: "",
        fields: [
          {
            type: "persons-select",
            name: "asset-owned-persons",
            required: true,
            verification: "checkbox",
            options: [
              {
                value: "other",
                label: "Lägg till utomstående person",
              },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "asset-owned-with-partner",
        values: ["yes"],
        op: "or",
      },
    ],
  },
  {
    id: "3.5.6",
    section: 3,
    set: 5,
    items: ADD_PERSON_ITEMS,
    multiple: "asset-owned-persons-other",
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "asset-owned-persons",
        values: ["other"],
        op: "or",
      },
    ],
  },
  {
    id: "3.4.7",
    section: 3,
    set: 5,
    items: [
      {
        label: "Hur stor är din del av tillgången?",
        fields: [
          {
            type: "number",
            name: "asset-owned-with-partner-share",
            required: true,
            verification: "percentage",
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "asset-owned-with-partner",
        values: ["yes"],
        op: "or",
      },
    ],
  },
  {
    id: "3.5.10",
    section: 3,
    set: 5,
    items: [
      {
        label:
          "Vill du testamentera tillgången till någon specifik person eller organisation?",
        fields: [
          {
            type: "radio",
            name: "bequeath-asset",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: ASSET_TO_PERSON_FIELD,
        values: ["yes"],
        op: "or",
      },
    ],
  },
  {
    id: "3.5.11.1",
    section: 3,
    set: 5,
    items: [
      {
        label: "Välj vem eller vilka som ska ärva tillgången",
        fields: [
          {
            type: "persons-select",
            name: "asset-bequeath-persons",
            required: true,
            verification: "checkbox",
            options: [
              {
                value: "other",
                label: "Lägg till utomstående person",
              },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "bequeath-asset",
        values: ["yes"],
        op: "or",
      },
    ],
  },
  {
    id: "3.5.11.2",
    section: 3,
    set: 5,
    items: ADD_PERSON_ORG_ITEMS,
    multiple: "asset-bequeath-persons-other",
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "asset-bequeath-persons",
        values: ["other"],
        op: "or",
      },
    ],
  },
  {
    id: "3.5.11.3",
    section: 3,
    set: 5,
    items: [
      {
        label:
          "Ska arvet fördelas i lika stora andelar mellan personerna du har angivit?",
        info: {
          text: [
            "Du kan helt fritt välja vilka andelar var och en av de du testamenterar till ska erhålla. Vill du fördela arvet i andra proportioner än vad som framgår av lag går det jättebra. Bröstarvingar har dock alltid rätt att begära jämkning av testamentet för att få ut sin laglott ändå, men gör de inte det blir fördelningen som du angett. Mer information om detta längre ner i det här kapitlet.",
            "<span class='font-intermedium'>Svarsalternativ 1</span>: Ja",
            "Välj detta alternativ om du vill att alla dina arvingar ska få lika stora andelar av arvet efter dig. Om du vill testamentera till dina barn, barnbarn, syskonbarn eller liknande och vill att de ska ärva exakt lika mycket är detta ett bra alternativ.",
            "<span class='font-intermedium'>Svarsalternativ 2</span>: Nej",
            "Välj detta alternativ om du vill att dina arvingar ska få olika stora andelar av arvet efter dig. Exempelvis kan du vilja ge 50% till en välgörenhetsorganisation, 30% till en släkting och 20% till din vän.",
          ],
          id: "3.5.11.3",
        },
        fields: [
          {
            type: "radio",
            name: "asset-inheritance-type",
            required: true,
            verification: "radio",
            options: [
              {
                value: "equal",
                label: "Ja, arvet ska fördelas i lika stora andelar",
              },
              {
                value: "unequal",
                label: "Nej, arvet ska fördelas i olika stora andelar",
              },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value-count",
        name: "asset-bequeath-persons",
        values: 2,
        op: ">=",
      },
    ],
  },
  {
    id: "3.5.11.4",
    section: 3,
    set: 5,
    items: [
      {
        label: "Hur ska arvet efter dig fördelas?",
        fields: [
          {
            type: "persons-distribution-asset",
            name: "asset-inheritance-distribution",
            required: true,
            verification: "percentage",
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "asset-inheritance-type",
        values: ["unequal"],
        op: "and",
      },
    ],
  },

  {
    id: "3.6",
    section: 3,
    set: 6,
    items: [
      {
        label: "",
        fields: [
          {
            type: "radio",
            name: "assets-data-sharing-consent",
            options: [
              {
                value: "yes",
                label:
                  "Jag samtycker att dela med mig av datan angående mina tillgångar och skulder",
              },
              {
                value: "no",
                label:
                  "Jag samtycker inte att dela med mig av datan angående mina tillgångar och skulder",
              },
            ],
            required: true,
            verification: "radio",
          },
        ],
      },
    ],
    excludeFromSummary: true,
  },
  {
    id: "3.7",
    section: 3,
    set: 7,
    items: [
      {
        label: "Tillåtelse att dela data",
        fields: [
          {
            type: "radio",
            name: "assets-data-sharing-consent",
            options: [
              {
                value: "yes",
                label: "Ja",
              },
              {
                value: "no",
                label: "Nej",
              },
            ],
            required: true,
            verification: "radio",
          },
        ],
      },
    ],
    excludeFromSummary: false,
  },

  {
    id: "4.2.1",
    section: 4,
    set: 1,
    items: [
      {
        label: "",
        desc: "<div class='flex flex-col gap-5 mb-5'><p class='font-intermedium text-dark-blue'>INLEDANDE INFORMATION</p><p class='font-intermedium'>Skapa din framtidsfullmakt</p><p>Utgångspunkten är att den framtidsfullmakt du skapar kommer att vara en heltäckande framtidsfullmakt. Det innebär att personen du ger fullmakt till ska kunna göra allt som du själv kan göra idag.</p><p>Kom ihåg att detta innebär att den person du ger fullmakt till har ett oinskränkt uppdrag och endast är bunden av de begränsningar som följer enligt lag. Av den anledningen är det viktigt att du endast lämnar fullmakt till någon eller några som du litar på att de agerar utifrån ditt bästa. Vi råder dig även till att prata igenom innehållet i fullmakten med personen innan du upprättar en framtidsfullmakt.</p><p>Oavsett hur du skriver din Framtidsfullmakt, kommer den utvalda personen varken kunna eller få vidta åtgärder som omfattas av hälso- och sjukvårdslagen eller tandvårdslagen.</p></div>",
        fields: [
          {
            type: "checkbox",
            name: "lpa-create-confirm",
            required: true,
            verification: "checkbox",
            options: [
              {
                value: "yes",
                label: "Jag förstår!",
              },
            ],
          },
        ],
      },
    ],
    excludeFromSummary: true,
  },
  {
    id: "4.1.1",
    section: 4,
    set: 2,
    items: [
      {
        label: "Vem ska få fullmakten?",
        info: {
          text: [
            "<span class='font-intermedium'>Svarsalternativ 1</span>: En person",
            "Om du endast vill ge en person fullmakt är det viktigt att komma ihåg att en god man kommer behöva utses om personen du utsett inte vill eller kan ta sig an uppdraget. Du kan i nästa fråga utse upp till två ersättare om du vill utse en person att ge fullmakt till.",
            "<span class='font-intermedium'>Svarsalternativ 2</span>: Flera personer",
            "Om du väljer att utse flera personer att ge fullmakt till kommer de företräda dig samtidigt vilket ställer vissa krav på god kommunikation och samarbete mellan de utvalda personerna. Du kan i nästa fråga välja om du vill att personerna ska agera tillsammans eller enskilt.",
          ],
          id: "",
        },
        fields: [
          {
            type: "persons-select",
            name: "lpa-principal-persons",
            required: true,
            verification: "checkbox",
            options: [
              {
                value: "other",
                label: "Lägg till utomstående person",
              },
            ],
            excludePersonTypes: ["org"],
          },
        ],
      },
    ],
  },
  {
    id: "4.2.1.1",
    section: 4,
    set: 2,
    items: ADD_PERSON_ITEMS,
    multiple: "lpa-principal-persons-other",
    type: "input-group",
    wrapperClasses: QUESTION_WRAPPER_TOP_MARGIN,
    dependencies: [
      {
        type: "value",
        name: "lpa-principal-persons",
        values: ["other"],
        op: "or",
      },
    ],
  },
  {
    id: "4.2.2",
    section: 4,
    set: 2,
    items: [
      {
        label:
          "Vill du utse en andra person att agera om %lpa-principal-persons% inte kan eller vill?",
        fields: [
          {
            type: "radio",
            name: "lpa-principal-person-second-hand-add",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value-count",
        name: "lpa-principal-persons",
        values: 1,
        op: "=",
      },
    ],
  },
  {
    id: "4.2.3",
    section: 4,
    set: 2,
    items: [
      {
        label: "",
        fields: [
          {
            type: "persons-select",
            name: "lpa-principal-person-second-hand",
            required: true,
            verification: "radio",
            options: [
              {
                value: "other",
                label: "Lägg till utomstående person",
              },
            ],
            excludeValuesFromName: ["lpa-principal-persons"],
            excludePersonTypes: ["org"],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "lpa-principal-person-second-hand-add",
        values: ["yes"],
        op: "and",
      },
    ],
    wrapperClasses: QUESTION_WRAPPER_TOP_MARGIN,
  },
  {
    id: "4.2.3.1",
    section: 4,
    set: 2,
    items: [
      {
        label: "Personens namn",
        fields: [
          {
            type: "input",
            name: "lpa-principal-person-second-hand-first-name",
            placeholder: "Förnamn",
            required: false,
            verification: "text",
          },
          {
            type: "input",
            name: "lpa-principal-person-second-hand-last-name",
            placeholder: "Efternamn",
            required: false,
            verification: "text",
          },
        ],
      },
      {
        label: "När är personen född?",
        fields: [
          {
            type: "input",
            name: "lpa-principal-person-second-hand-birthday",
            placeholder: "Personnummer med/utan 4 sista siffrorna",
            required: true,
            verification: "birthday-personnummer",
          },
        ],
      },
      {
        label: "Vad är er relation?",
        fields: [
          {
            type: "select",
            name: "lpa-principal-person-second-hand-relation",
            placeholder: "Välj ett alternativ",
            required: false,
            verification: "text",
            options: RELATION_OPTIONS,
          },
        ],
      },
    ],
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "lpa-principal-person-second-hand",
        values: ["other"],
        op: "or",
      },
    ],
    wrapperClasses: QUESTION_WRAPPER_TOP_MARGIN,
  },
  {
    id: "4.2.4",
    section: 4,
    set: 2,
    items: [
      {
        label:
          "Om varken %lpa-principal-persons% eller %lpa-principal-person-second-hand% kan träda in, vill du utse en tredje person att agera?",
        fields: [
          {
            type: "radio",
            name: "lpa-principal-person-third-hand-add",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "lpa-principal-person-second-hand-add",
        values: ["yes"],
        op: "and",
      },
    ],
  },
  {
    id: "4.2.5",
    section: 4,
    set: 2,
    items: [
      {
        label: "",
        fields: [
          {
            type: "persons-select",
            name: "lpa-principal-person-third-hand",
            required: true,
            verification: "checkbox",
            options: [
              {
                value: "other",
                label: "Lägg till utomstående person",
              },
            ],
            excludeValuesFromName: [
              "lpa-principal-persons",
              "lpa-principal-person-second-hand",
            ],
            excludePersonTypes: ["org"],
          },
        ],
      },
    ],
    wrapperClasses: QUESTION_WRAPPER_TOP_MARGIN,
    dependencies: [
      {
        type: "value",
        name: "lpa-principal-person-third-hand-add",
        values: ["yes"],
        op: "and",
      },
    ],
  },
  {
    id: "4.2.5.1",
    section: 4,
    set: 2,
    items: [
      {
        label: "Personens namn",
        fields: [
          {
            type: "input",
            name: "lpa-principal-person-third-hand-first-name",
            placeholder: "Förnamn",
            required: false,
            verification: "text",
          },
          {
            type: "input",
            name: "lpa-principal-person-third-hand-last-name",
            placeholder: "Efternamn",
            required: false,
            verification: "text",
          },
        ],
      },
      {
        label: "När är personen född?",
        fields: [
          {
            type: "input",
            name: "lpa-principal-person-third-hand-birthday",
            placeholder: "Personnummer med/utan 4 sista siffrorna",
            required: true,
            verification: "birthday-personnummer",
          },
        ],
      },
      {
        label: "Vad är er relation?",
        fields: [
          {
            type: "select",
            name: "lpa-principal-person-third-hand-relation",
            placeholder: "Välj ett alternativ",
            required: false,
            verification: "text",
            options: RELATION_OPTIONS,
          },
        ],
      },
    ],
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "lpa-principal-person-third-hand",
        values: ["other"],
        op: "or",
      },
    ],
    wrapperClasses: QUESTION_WRAPPER_TOP_MARGIN,
  },
  {
    id: "4.2.6",
    section: 4,
    set: 2,
    items: [
      {
        label:
          "Ska %lpa-principal-persons|lpa-principal-person-second-hand|lpa-principal-person-third-hand% agera tillsammans eller enskilt?",
        info: {
          text: [
            "Om du anser att det bästa för dig vore att flera personer ska företräda dig samtidigt, finns det olika sätt att utforma fullmakten. Nedan kommer du att ställas inför två val.",
            "<span class='font-intermedium'>Svarsalternativ 1</span>: Tillsammans",
            "Om du väljer att personerna ska agera tillsammans innebär det att de behöver vara eniga om alla beslut de tar inom ramen för framtidsfullmakten.",
            "I praktiken innebär detta att samtliga personer behöver vidta alla åtgärder gemensamt, till exempel behöver alla valda personer signera deklarationen och gemensamt besöka banken för att betala räkningar. Det kan leda till att förvaltningen blir tungrodd och komplicerad.",
            "<span class='font-intermedium'>Svarsalternativ 2</span>: Enskilt",
            "Om du vill ge de utvalda personerna möjlighet att agera var och en för sig är det viktigt att tänka på att det kan uppstå oreda och förvaltningen kan bli spretig om de du väljer är oeniga om hur förvaltningen ska ske.",
            "I praktiken innebär detta alternativ till exempel att en av de du givit fullmakt en dag kan köpa värdepapper för din räkning, medan någon av de andra du också givit fullmakt kan sälja samma värdepapper dagen efter.",
          ],
          id: "",
        },
        fields: [
          {
            type: "radio",
            name: "lpa-principal-action-type",
            required: true,
            verification: "radio",
            options: [
              { value: "individual", label: "Enskilt" },
              { value: "together", label: "Tillsammans" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value-count",
        name: "lpa-principal-persons",
        values: 2,
        op: ">=",
      },
    ],
  },
  {
    id: "4.2.7",
    section: 4,
    set: 2,
    items: [
      {
        label:
          "Vill du att framtidsfullmakten även ska omfatta överlåtelse av bostadsrätt, fastighet eller tomträtt som du äger idag eller i framtiden kan komma att äga?",
        info: {
          text: [
            "Om du vill att den du ger fullmakten till ska kunna ta beslut om den bostad du äger när framtidsfullmakten aktiveras, behöver detta tydligt specificeras i avtalet. Besluten kan inkludera försäljning av bostad, renoveringar och att ta nya lån.",
            "<span class='font-intermedium'>Svarsalternativ 1</span>: Ja",
            "Välj ja om du vill att den du ger fullmakten till exempelvis ska kunna sälja din bostad för din räkning.",
            "<span class='font-intermedium'>Svarsalternativ 2</span>: Nej",
            "Om du svarar nej på denna fråga kommer framtidsfullmakten inte att omfatta bostadsförsäljning eller överlåtelser. Istället kommer en god man att behöva utses vid en eventuell bostadsförsäljning.",
          ],
          id: "",
        },
        fields: [
          {
            type: "radio",
            name: "lpa-property-transfer",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "4.2.8a",
    section: 4,
    set: 2,
    items: [
      {
        label:
          "Eftersom att du och %lpa-principal-persons% äger %asset-by-person|lpa-principal-persons|asset-owned-persons% tillsammans rekommenderar vi att du utser ytterligare en person för att undvika eventuellt jäv. Vem ska få samma behörighet som %lpa-principal-persons% gällande eventuell överlåtelse av egendomen?",
        fields: [
          {
            type: "persons-select",
            name: "lpa-property-conflict-person-a",
            required: true,
            verification: "radio",
            options: [
              {
                value: "other",
                label: "Lägg till utomstående person",
              },
            ],
            excludeValuesFromName: [
              "lpa-principal-persons",
              "lpa-principal-person-second-hand",
              "lpa-principal-person-third-hand",
              "assets-owners",
            ],
            excludePersonTypes: ["org"],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value-count",
        name: "lpa-principal-persons",
        values: 2,
        op: ">=",
      },
      {
        type: "value",
        name: "lpa-principal-action-type",
        values: ["individual"],
        op: "and",
      },
      {
        type: "value",
        name: "lpa-property-transfer",
        values: ["yes"],
        op: "and",
      },
      {
        type: "asset-person",
        assetType: "real-estate",
        person: [
          "lpa-principal-persons",
          "lpa-principal-person-second-hand",
          "lpa-principal-person-third-hand",
        ],
        in: "asset-owned-persons",
      },
    ],
  },
  {
    id: "4.2.8.1a",
    section: 4,
    set: 2,
    items: [
      {
        label: "Personens namn",
        fields: [
          {
            type: "input",
            name: "lpa-property-conflict-person-a-first-name",
            placeholder: "Förnamn",
            required: false,
            verification: "text",
          },
          {
            type: "input",
            name: "lpa-property-conflict-person-a-last-name",
            placeholder: "Efternamn",
            required: false,
            verification: "text",
          },
        ],
      },
      {
        label: "När är personen född?",
        fields: [
          {
            type: "input",
            name: "lpa-property-conflict-person-a-birthday",
            placeholder: "Personnummer med/utan 4 sista siffrorna",
            required: true,
            verification: "birthday-personnummer",
          },
        ],
      },
      {
        label: "Vad är er relation?",
        fields: [
          {
            type: "select",
            name: "lpa-property-conflict-person-a-relation",
            placeholder: "Välj ett alternativ",
            required: false,
            verification: "text",
            options: RELATION_OPTIONS,
          },
        ],
      },
    ],
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "lpa-property-conflict-person-a",
        values: ["other"],
        op: "or",
      },
    ],
    wrapperClasses: QUESTION_WRAPPER_TOP_MARGIN,
  },
  {
    id: "4.2.8b",
    section: 4,
    set: 2,
    items: [
      {
        label:
          "Eftersom att du och %lpa-principal-persons% äger %asset-by-person|lpa-principal-persons|asset-owned-persons% tillsammans rekommenderar vi att du utser ytterligare en person för att undvika eventuellt jäv. Vem ska få samma behörighet som %lpa-principal-persons% gällande eventuell överlåtelse av egendomen?",
        fields: [
          {
            type: "persons-select",
            name: "lpa-property-conflict-person-b",
            required: true,
            verification: "radio",
            options: [
              {
                value: "other",
                label: "Lägg till utomstående person",
              },
            ],
            excludeValuesFromName: [
              "lpa-principal-persons",
              "lpa-principal-person-second-hand",
              "lpa-principal-person-third-hand",
              "assets-owners",
            ],
            excludePersonTypes: ["org"],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value-count",
        name: "lpa-principal-persons",
        values: 2,
        op: ">=",
      },
      {
        type: "value",
        name: "lpa-principal-action-type",
        values: ["together"],
        op: "and",
      },
      {
        type: "value",
        name: "lpa-property-transfer",
        values: ["yes"],
        op: "and",
      },
      {
        type: "asset-person",
        assetType: "real-estate",
        person: [
          "lpa-principal-persons",
          "lpa-principal-person-second-hand",
          "lpa-principal-person-third-hand",
        ],
        in: "asset-owned-persons",
      },
    ],
  },
  {
    id: "4.2.8.1b",
    section: 4,
    set: 2,
    items: [
      {
        label: "Personens namn",
        fields: [
          {
            type: "input",
            name: "lpa-property-conflict-person-b-first-name",
            placeholder: "Förnamn",
            required: false,
            verification: "text",
          },
          {
            type: "input",
            name: "lpa-property-conflict-person-b-last-name",
            placeholder: "Efternamn",
            required: false,
            verification: "text",
          },
        ],
      },
      {
        label: "När är personen född?",
        fields: [
          {
            type: "input",
            name: "lpa-property-conflict-person-b-birthday",
            placeholder: "Personnummer med/utan 4 sista siffrorna",
            required: true,
            verification: "birthday-personnummer",
          },
        ],
      },
      {
        label: "Vad är er relation?",
        fields: [
          {
            type: "select",
            name: "lpa-property-conflict-person-b-relation",
            placeholder: "Välj ett alternativ",
            required: false,
            verification: "text",
            options: RELATION_OPTIONS,
          },
        ],
      },
    ],
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "lpa-property-conflict-person-b",
        values: ["other"],
        op: "or",
      },
    ],
    wrapperClasses: QUESTION_WRAPPER_TOP_MARGIN,
  },
  {
    id: "4.2.8c",
    section: 4,
    set: 2,
    items: [
      {
        label:
          "Eftersom att du och %lpa-principal-persons% äger %asset-by-person|lpa-principal-persons|asset-owned-persons% tillsammans rekommenderar vi att du utser ytterligare en person för att undvika eventuellt jäv. Vem ska få samma behörighet som %lpa-principal-persons% gällande eventuell överlåtelse av egendomen?",
        fields: [
          {
            type: "persons-select",
            name: "lpa-property-conflict-person-c",
            required: true,
            verification: "radio",
            options: [
              {
                value: "other",
                label: "Lägg till utomstående person",
              },
            ],
            excludeValuesFromName: [
              "lpa-principal-persons",
              "lpa-principal-person-second-hand",
              "lpa-principal-person-third-hand",
              "assets-owners",
            ],
            excludePersonTypes: ["org"],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value-count",
        name: "lpa-principal-persons",
        values: 1,
        op: "=",
      },
      {
        type: "value",
        name: "lpa-property-transfer",
        values: ["yes"],
        op: "and",
      },
      {
        type: "asset-person",
        assetType: "real-estate",
        person: [
          "lpa-principal-persons",
          "lpa-principal-person-second-hand",
          "lpa-principal-person-third-hand",
        ],
        in: "asset-owned-persons",
      },
    ],
  },
  {
    id: "4.2.8.1c",
    section: 4,
    set: 2,
    items: [
      {
        label: "Personens namn",
        fields: [
          {
            type: "input",
            name: "lpa-property-conflict-person-c-first-name",
            placeholder: "Förnamn",
            required: false,
            verification: "text",
          },
          {
            type: "input",
            name: "lpa-property-conflict-person-c-last-name",
            placeholder: "Efternamn",
            required: false,
            verification: "text",
          },
        ],
      },
      {
        label: "När är personen född?",
        fields: [
          {
            type: "input",
            name: "lpa-property-conflict-person-c-birthday",
            placeholder: "Personnummer med/utan 4 sista siffrorna",
            required: true,
            verification: "birthday-personnummer",
          },
        ],
      },
      {
        label: "Vad är er relation?",
        fields: [
          {
            type: "select",
            name: "lpa-property-conflict-person-c-relation",
            placeholder: "Välj ett alternativ",
            required: false,
            verification: "text",
            options: RELATION_OPTIONS,
          },
        ],
      },
    ],
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "lpa-property-conflict-person-c",
        values: ["other"],
        op: "or",
      },
    ],
    wrapperClasses: QUESTION_WRAPPER_TOP_MARGIN,
  },
  {
    id: "4.2.9",
    section: 4,
    set: 2,
    items: [
      {
        label:
          "Ska %lpa-principal-persons|lpa-principal-person-second-hand|lpa-principal-person-third-hand% för din räkning, få lämna gåvor till andra?",
        info: {
          text: [
            "Den person du har valt får enligt lag lämna gåvor till andra än sig själv, för din räkning. Gåvornas storlek måste stå i proportion till din ekonomi och denna bedömning görs av den du ger fullmakt till.",
            "De gåvor som enligt lag får ges till andra än personen med fullmakt är så kallade personliga presenter som oftast ges till anhöriga vid födelsedagar och andra högtider.",
            "<span class='font-intermedium'>Svarsalternativ 1</span>: Ja, specifikt belopp",
            "I din framtidsfullmakt kan du själv reglera hur stor summa som årligen ska få lämnas som gåva, för din räkning. Anger du en specifik summa för gåvor i din framtidsfullmakt får beloppet inte överskridas.",
            "<span class='font-intermedium'>Svarsalternativ 2</span>: Ja, skäligt belopp",
            "Om du vill att gåvor ska få lämnas i enlighet med vad som gäller enligt lag, ska du välja detta alternativ. Det blir då upp till den du har valt att ta beslut om gåvornas storlek till andra, utifrån en skälighetsbedömning. Observera att gåvor enligt detta alternativ inte omfattar bostäder.",
            "<span class='font-intermedium'>Svarsalternativ 3</span>: Ja, begränsat belopp",
            "Om du vill att den person du valt obegränsat ska få ge gåvor till andra för din räkning, inklusive eventuell bostad du äger, ska du välja detta alternativ.",
            "<span class='font-intermedium'>Svarsalternativ 4</span>: Nej",
            "Om du inte vill att några gåvor ska kunna ges för din räkning ska du svara nej på denna fråga.",
          ],
          id: "",
        },
        fields: [
          {
            type: "radio",
            name: "lpa-gifting-others-type",
            required: true,
            verification: "radio",
            options: [
              { value: "yes-specific", label: "Ja, specifikt belopp" },
              { value: "yes-reasonable", label: "Ja, skäligt belopp" },
              {
                value: "yes-unlimited",
                label:
                  "Ja, obegränsat belopp (inklusive bostadsrätt, fastighet eller tomträtt)",
              },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value-count",
        name: "lpa-principal-persons",
        values: 1,
        op: ">=",
      },
    ],
  },
  {
    id: "4.2.9.1",
    section: 4,
    set: 2,
    items: [
      {
        label: "",
        fields: [
          {
            type: "input",
            name: "lpa-gifting-others-amount",
            placeholder: "Ange årligt belopp (endast siffror)",
            required: true,
            verification: "text",
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "lpa-gifting-others-type",
        values: ["yes-specific"],
        op: "and",
      },
    ],
    nested: {
      itemId: "4.2.9",
      fieldName: "lpa-gifting-others-type",
      value: "yes-specific",
    },
  },
  {
    id: "4.2.10",
    section: 4,
    set: 2,
    items: [
      {
        label:
          "Ska %lpa-principal-persons|lpa-principal-person-second-hand|lpa-principal-person-third-hand% för din räkning, få lämna gåvor till sig själv?",
        info: {
          text: [
            "Huvudregeln är att den du ger rätt att företräda dig inte kan ge gåvor till sig själv, för din räkning. Däremot är det något du kan möjliggöra genom att skriva in det i din framtidsfullmakt. Om du önskar att personen du valt ska kunna ta emot till exempel pengar, fastighet eller bostadsrätt som gåva från dig behöver detta tydligt framgå i fullmakten.",
            "<span class='font-intermedium'>Svarsalternativ 1</span>: Ja, specifikt belopp",
            "I din framtidsfullmakt kan du själv reglera hur stor summa som årligen ska få lämnas som gåva, för din räkning. Anger du en specifik summa för gåvor i din framtidsfullmakt får beloppet inte överskridas.",
            "<span class='font-intermedium'>Svarsalternativ 2</span>: Ja, skäligt belopp",
            "Om du vill att personen du valt på egen hand ska ta beslut om gåvornas storlek till sig själv utifrån en skälighetsbedömning ska du välja detta alternativ. Observera att gåvor enligt detta alternativ inte omfattar bostäder.",
            "<span class='font-intermedium'>Svarsalternativ 3</span>: Ja, begränsat belopp",
            "Om du vill att den person du valt obegränsat ska få ge gåvor till sig själv för din räkning, inklusive eventuell bostad du äger, ska du välja detta alternativ.",
            "<span class='font-intermedium'>Svarsalternativ 4</span>: Nej",
            "Om du inte vill att personen du valt ska kunna ge gåvor till sig själv från dig, oavsett vilka gåvor det kan handla om, ska du svara “nej” här. Viktigt att veta är att inga gåvor alls kommer kunna ges till personen i fråga från dig.",
          ],
          id: "",
        },
        fields: [
          {
            type: "radio",
            name: "lpa-gifting-self-type",
            required: true,
            verification: "radio",
            options: [
              { value: "yes-specific", label: "Ja, specifikt belopp" },
              { value: "yes-reasonable", label: "Ja, skäligt belopp" },
              {
                value: "yes-unlimited",
                label:
                  "Ja, obegränsat belopp (inklusive bostadsrätt, fastighet eller tomträtt)",
              },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value-count",
        name: "lpa-principal-persons",
        values: 1,
        op: ">=",
      },
    ],
  },
  {
    id: "4.2.10.1",
    section: 4,
    set: 2,
    items: [
      {
        label: "",
        fields: [
          {
            type: "input",
            name: "lpa-gifting-self-amount",
            placeholder: "Ange årligt belopp (endast siffror)",
            required: true,
            verification: "text",
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "lpa-gifting-self-type",
        values: ["yes-specific"],
        op: "and",
      },
    ],
    nested: {
      itemId: "4.2.10",
      fieldName: "lpa-gifting-self-type",
      value: "yes-specific",
    },
  },
  {
    id: "4.2.11",
    section: 4,
    set: 2,
    items: [
      {
        label:
          "Vill du att arvode ska utgå till %lpa-principal-persons|lpa-principal-person-second-hand|lpa-principal-person-third-hand%?",
        info: {
          text: [
            "Att arvode utgår till den du ger förtroende att företräda dig betyder att hen får en viss ekonomisk ersättning för sitt arbete. Vad arvodet uppgår till beror på uppdragets omfattning och hur du i din fullmakt anger att arvodet ska beräknas.",
            "Många väljer att ge uppdrag om framtidsfullmakt utan arvode men du väljer själv vad som ska gälla i din framtidsfullmakt.",
            "<span class='font-intermedium'>Svarsalternativ 1 och 2</span>: Ja, specifikt eller skäligt belopp",
            "Om du vill att arvode ska utgå är det viktigt att vara medveten om att en förenklad arbetsgivardeklaration ska upprättas för din räkning. Dessutom ska preliminärskatt och arbetsgivaravgifter betalas in till ditt skattekonto.",
            "<span class='font-intermedium'>Svarsalternativ 3</span>: Nej",
            "Om du väljer detta alternativ innebär det att personen du utser inte kommer att få betalt för sitt arbete att företräda dig.",
          ],
          id: "",
        },
        fields: [
          {
            type: "radio",
            name: "lpa-fee-type",
            required: true,
            verification: "radio",
            options: [
              { value: "yes-specific", label: "Ja, specifikt belopp" },
              { value: "yes-reasonable", label: "Ja, skäligt belopp" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value-count",
        name: "lpa-principal-persons",
        values: 1,
        op: ">=",
      },
    ],
  },
  {
    id: "4.2.11.1",
    section: 4,
    set: 2,
    items: [
      {
        label: "",
        fields: [
          {
            type: "input",
            name: "lpa-fee-amount",
            placeholder: "Ange årligt belopp (endast siffror)",
            required: true,
            verification: "text",
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "lpa-fee-type",
        values: ["yes-specific"],
        op: "and",
      },
    ],
    nested: {
      itemId: "4.2.11",
      fieldName: "lpa-fee-type",
      value: "yes-specific",
    },
  },
  {
    id: "4.2.12.0",
    section: 4,
    set: 2,
    items: [
      {
        label:
          "Vill du ge dina närmaste anhöriga rätt att begära årlig redovisning?",
        info: {
          text: [
            "Dina anhöriga har rätt att begära redovisning en gång per år från personen du utsett att ta beslut åt dig. Att lämna redovisning innebär att personen i fråga visar vad denne har gjort inom ramen för uppdraget. Det kan gälla specifikt efterfrågad information eller årsredovisning.",
            "En årsredovisning kan exempelvis innehålla redogörelse för fullmaktsgivarens ekonomi genom att tillgångar och skulder vid årets början och slut redovisas tillsammans med utgifter och inkomster under året.",
            "<span class='font-intermedium'>Svarsalternativ 1</span>: Ja",
            "Om du vill göra det enkelt för dina närmaste anhöriga att få insyn i arbetet som personen du utsett utför ska du svara ja på denna fråga.",
            "<span class='font-intermedium'>Svarsalternativ 2</span>: Nej",
            "Välj detta svarsalternativ om du inte vill att dina närmaste anhöriga ska vara involverade och istället föredrar att utse en utomstående granskare. Du ges i nästa steg möjlighet att utse en granskare om du svarar nej på denna fråga.",
          ],
          id: "",
        },
        fields: [
          {
            type: "radio",
            name: "lpa-request-annual-report",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "lpa-audit-type",
        values: ["yes"],
        op: "and",
      },
    ],
  },
  {
    id: "4.2.12",
    section: 4,
    set: 2,
    items: [
      {
        label: "Ska granskare utses?",
        info: {
          text: [
            "I din framtidsfullmakt har du möjlighet att utse en utomstående person att granska arbetet som utförs av den som företräder dig.",
            "<span class='font-intermedium'>Svarsalternativ 1</span>: Ja",
            "Väljer du att utse en granskare har dina anhöriga inte någon rätt att begära årlig redovisning av din företrädares arbete, eftersom det istället ska göras av granskaren.",
            "<span class='font-intermedium'>Svarsalternativ 2</span>: Nej",
            "Om du inte utser en granskare har dina anhöriga enligt lag rätt att begära redovisning av din företrädares arbete en gång per år.",
          ],
          id: "",
        },
        fields: [
          {
            type: "radio",
            name: "lpa-audit-type",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "lpa-request-annual-report",
        values: ["no"],
        op: "and",
      },
    ],
  },
  {
    id: "4.2.13",
    section: 4,
    set: 2,
    items: [
      {
        label: "",
        fields: [
          {
            type: "persons-select",
            name: "lpa-audit-persons",
            required: true,
            verification: "radio",
            options: [
              {
                value: "other",
                label: "Lägg till utomstående person",
              },
            ],
            excludeValuesFromName: [
              "lpa-principal-persons",
              "lpa-principal-person-second-hand",
              "lpa-principal-person-third-hand",
              "lpa-property-conflict-person-a",
              "lpa-property-conflict-person-b",
              "lpa-property-conflict-person-c",
            ],
            excludePersonTypes: ["org"],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "lpa-audit-type",
        values: ["yes"],
        op: "and",
      },
    ],
    wrapperClasses: QUESTION_WRAPPER_TOP_MARGIN,
  },
  {
    id: "4.2.13.1",
    section: 4,
    set: 2,
    items: [
      {
        label: "Personens namn",
        fields: [
          {
            type: "input",
            name: "lpa-audit-persons-first-name",
            placeholder: "Förnamn",
            required: false,
            verification: "text",
          },
          {
            type: "input",
            name: "lpa-audit-persons-last-name",
            placeholder: "Efternamn",
            required: false,
            verification: "text",
          },
        ],
      },
      {
        label: "När är personen född?",
        fields: [
          {
            type: "input",
            name: "lpa-audit-persons-birthday",
            placeholder: "Personnummer med/utan 4 sista siffrorna",
            required: true,
            verification: "birthday-personnummer",
          },
        ],
      },
      {
        label: "Vad är er relation?",
        fields: [
          {
            type: "select",
            name: "lpa-audit-persons-relation",
            placeholder: "Välj ett alternativ",
            required: false,
            verification: "text",
            options: RELATION_OPTIONS,
          },
        ],
      },
    ],
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "lpa-audit-persons",
        values: ["other"],
        op: "or",
      },
    ],
    wrapperClasses: QUESTION_WRAPPER_TOP_MARGIN,
  },
  {
    id: "4.2.14",
    section: 4,
    set: 2,
    items: [
      {
        label:
          "Ska %lpa-audit-persons% ha rätt att återkalla framtidsfullmakten om %lpa-audit-persons% anser att %lpa-principal-persons|lpa-principal-person-second-hand|lpa-principal-person-third-hand% missköter sitt uppdrag?",
        info: {
          text: [
            "En utsedd granskare har rätt att återkalla framtidsfullmakten om detta anges särskilt i fullmakten. Granskarens återkallelse kan bara ske när fullmakten har aktiverats.",
            "Återkallelse sker genom att antingen återta fullmakten, förstöra den eller genom att förklara fullmakten kraftlös.",
            "Värt att veta är att vem som helst kan anmäla till Överförmyndarnämnden om en framtidsfullmakt missköts eller missbrukas. Om Överförmyndarnämnden anser att det finns skäl, kan de ta beslut om att återkalla fullmakten helt eller delvis. Istället kan en god man då komma att utses i de delar som framtidsfullmakten inte längre gäller.",
            "<span class='font-intermedium'>Svarsalternativ 1</span>: Ja",
            "Välj alternativ “ja” om du vill att granskaren, utöver sin granskningsfunktion, också ska ha möjlighet att återkalla fullmakten om den missbrukas eller om uppdraget missköts.",
            "<span class='font-intermedium'>Svarsalternativ 2</span>: Nej",
            "Välj alternativ “nej” om du inte vill att granskaren ska ha möjlighet att återkalla fullmakten utan endast ska granska det arbete personen du utsett gör åt dig.",
          ],
          id: "",
        },
        fields: [
          {
            type: "radio",
            name: "lpa-audit-action-type",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "lpa-audit-type",
        values: ["yes"],
        op: "and",
      },
      {
        type: "value-count",
        name: "lpa-principal-persons",
        values: 1,
        op: ">=",
      },
    ],
  },
  {
    id: "4.2.15",
    section: 4,
    set: 2,
    items: [
      {
        label: "Vill du att arvode ska utgå till %lpa-audit-persons%?",
        fields: [
          {
            type: "radio",
            name: "lpa-audit-fee",
            required: true,
            verification: "radio",
            options: [
              { value: "yes-specific", label: "Ja, specifikt belopp" },
              { value: "yes-reasonable", label: "Ja, skäligt belopp" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "lpa-audit-type",
        values: ["yes"],
        op: "and",
      },
    ],
  },
  {
    id: "4.2.15.1",
    section: 4,
    set: 2,
    items: [
      {
        label: "",
        fields: [
          {
            type: "input",
            name: "lpa-audit-amount",
            placeholder: "Ange årligt belopp (endast siffror)",
            required: true,
            verification: "text",
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "lpa-audit-fee",
        values: ["yes-specific"],
        op: "and",
      },
    ],
    nested: {
      itemId: "4.2.15",
      fieldName: "lpa-audit-fee",
      value: "yes-specific",
    },
  },
  {
    id: "4.2.16",
    section: 4,
    set: 2,
    items: [
      {
        label: "På vilka villkor vill du att framtidsfullmakten ska aktiveras?",
        info: {
          text: [
            "En framtidsfullmakt kan aktiveras på flera sätt, vanligast är att den person du utsett bestämmer när det är dags att ta fullmakten i bruk.",
            "Vissa väljer att i sin framtidsfullmakt skriva att domstol ska avgöra när fullmakten ska börja gälla. Det kräver dock först en handläggning hos en domstol.",
            "<span class='font-intermedium'>Svarsalternativ 1</span>: Fullmaktshavaren avgör",
            "Detta svarsalternativ betyder att den du valt snabbt kan börja agera för din räkning om du blir plötsligt sjuk eller om brådskande åtgärder behöver vidtas. Personen är skyldig att informera dig och dina anhöriga om att hen, med stöd av framtidsfullmakten, börjar agera för din räkning.",
            "<span class='font-intermedium'>Svarsalternativ 2</span>: Domstol avgör",
            "Om du vill att framtidsfullmakten ska aktiveras först efter beslut från domstol behöver man vara medveten om att personen som ska få agera för din räkning inte kan börja göra det förrän en domstol har handlagt ärendet.",
          ],
          id: "",
        },
        fields: [
          {
            type: "radio",
            name: "lpa-take-effect-decision",
            required: true,
            verification: "radio",
            options: [
              {
                value: "representative",
                label: "%lpa-principal-persons% avgör",
              },
              { value: "court", label: "Domstol avgör" },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value-count",
        name: "lpa-principal-persons",
        values: 1,
        op: ">=",
      },
    ],
  },

  {
    id: "5.1.1",
    section: 5,
    set: 1,
    items: [
      {
        label: "Vill du att Fenix Family AB hanterar din begravning?",
        fields: [
          {
            type: "radio",
            name: "pln-fnx-manage-fun",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
  },
  /*  {
    id: "5.1.2",
    section: 5,
    set: 1,
    items: [
      {
        label:
          "Vill du underlätta för dina anhöriga genom att förbetala ditt begravningspaket?",
        fields: [
          {
            type: "radio",
            name: "pln-prepay-fun-package",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "5.1.3",
    section: 5,
    set: 1,
    items: [
      {
        label: "Vill du donera dina organ om det är möjligt?",
        fields: [
          {
            type: "radio",
            name: "pln-donate-organs",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
  },*/
  {
    id: "5.1.3.1",
    section: 5,
    set: 1,
    items: [
      {
        label:
          "Ange kontaktuppgifter till personen som ska få ta del av dina önskemål så ser vi till att de hamnar rätt",
        fields: [
          {
            type: "persons-select",
            name: "pln-contact-person",
            required: true,
            verification: "radio",
            options: [
              {
                value: "other",
                label: "Lägg till utomstående person",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "5.1.3.2",
    section: 5,
    set: 1,
    items: [
      {
        label: "Personens namn",
        fields: [
          {
            type: "input",
            name: "pln-contact-person-first-name",
            placeholder: "Förnamn",
            required: false,
            verification: "text",
          },
          {
            type: "input",
            name: "pln-contact-person-last-name",
            placeholder: "Efternamn",
            required: false,
            verification: "text",
          },
        ],
      },
      {
        label: "När är personen född?",
        fields: [
          {
            type: "input",
            name: "pln-contact-person-birthday",
            placeholder: "Personnummer med/utan 4 sista siffrorna",
            required: true,
            verification: "birthday-personnummer",
          },
        ],
      },
      {
        label: "Personens telefonnummer?",
        fields: [
          {
            type: "input",
            name: "pln-contact-person-phone",
            placeholder: "Telefonnummer",
            verification: "phone",
            required: true,
          },
        ],
      },
      {
        label: "Vad är er relation?",
        fields: [
          {
            type: "select",
            name: "pln-contact-person-relation",
            placeholder: "Välj ett alternativ",
            required: false,
            verification: "text",
            options: RELATION_OPTIONS,
          },
        ],
      },
    ],
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "pln-contact-person",
        values: ["other"],
        op: "or",
      },
    ],
  },
  {
    id: "5.1.3.3",
    section: 5,
    set: 1,
    items: [
      {
        label: "Personens telefonnummer?",
        fields: [
          {
            type: "input",
            name: "pln-contact-person-phone-number",
            placeholder: "Telefonnummer",
            verification: "phone",
            required: true,
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "pln-contact-person",
        values: ["other"],
        op: "notAnd",
      },
    ],
  },
  {
    id: "5.1.4",
    section: 5,
    set: 1,
    items: [
      {
        label:
          "Finns det personer du vill ska bli informerade om din bortgång och eventuella begravning?",
        fields: [
          {
            type: "radio",
            name: "pln-fp-inform",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "5.1.4.1",
    section: 5,
    set: 1,
    items: [
      {
        label: "",
        fields: [
          {
            type: "persons-select",
            name: "pln-fp-access",
            required: true,
            verification: "checkbox",
            options: [
              {
                value: "other",
                label: "Lägg till utomstående person",
              },
            ],
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "pln-fp-inform",
        values: ["yes"],
        op: "or",
      },
    ],
  },
  {
    id: "5.1.4.2",
    section: 5,
    set: 1,
    items: ADD_PERSON_ITEMS,
    multiple: "pln-fp-access-other",
    type: "input-group",
    dependencies: [
      {
        type: "value",
        name: "pln-fp-access",
        values: ["other"],
        op: "or",
      },
    ],
  },
  {
    id: "5.1.5",
    section: 5,
    set: 1,
    items: [
      {
        label: "Vill du att det hålls en ceremoni efter din bortgång?",
        fields: [
          {
            type: "radio",
            name: "pln-ceremony",
            required: true,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "5.1.6",
    section: 5,
    set: 1,
    items: [
      {
        label: "Vill du begravas i urna eller kista?",
        fields: [
          {
            type: "radio",
            name: "pln-bury-method",
            required: true,
            verification: "radio",
            options: [
              { value: "urna", label: "Jag vill begravas i urna" },
              { value: "kista", label: "Jag vill begravas i kista" },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "5.1.7",
    section: 5,
    set: 1,
    items: [
      {
        label: "Har du någon speciell plats i åtanke för din sista vila?",
        fields: [
          {
            type: "input",
            name: "pln-bury-place",
            required: true,
            verification: "text",
          },
        ],
      },
    ],
  },
  {
    id: "5.1.8",
    section: 5,
    set: 1,
    items: [
      {
        label: "Vad är din favoritfärg?",
        fields: [
          {
            type: "input",
            name: "pln-fav-colour",
            required: false,
            verification: "text",
          },
        ],
      },
    ],
  },
  {
    id: "5.1.9",
    section: 5,
    set: 1,
    items: [
      {
        label: "Har du någon älsklingsblomma?",
        fields: [
          {
            type: "input",
            name: "pln-fav-flower",
            required: false,
            verification: "text",
          },
        ],
      },
    ],
  },
  {
    id: "5.1.10",
    section: 5,
    set: 1,
    items: [
      {
        label: "Finns det någon låt du tycker extra mycket om?",
        fields: [
          {
            type: "input",
            name: "pln-fav-song",
            required: false,
            verification: "text",
          },
        ],
      },
    ],
  },
  {
    id: "5.1.11",
    section: 5,
    set: 1,
    items: [
      {
        label: "Poem",
        fields: [
          {
            type: "input",
            name: "pln-poem",
            required: false,
          },
        ],
      },
    ],
    hidden: true,
  },
  {
    id: "5.1.12",
    section: 5,
    set: 1,
    items: [
      {
        label: "Name",
        fields: [
          {
            type: "input",
            name: "pln-name",
            required: false,
          },
        ],
      },
    ],
    hidden: true,
  },
  {
    id: "5.1.13",
    section: 5,
    set: 1,
    items: [
      {
        label: "Finns det något annat du vill tillägga?",
        fields: [
          {
            type: "radio",
            name: "pln-notes-enabled",
            required: false,
            verification: "radio",
            options: [
              { value: "yes", label: "Ja" },
              { value: "no", label: "Nej" },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "5.1.14",
    section: 5,
    set: 1,
    items: [
      {
        label: "",
        fields: [
          {
            type: "textarea",
            name: "pln-notes",
            required: true,
            verification: "text",
          },
        ],
      },
    ],
    dependencies: [
      {
        type: "value",
        name: "pln-notes-enabled",
        values: ["yes"],
        op: "and",
      },
    ],
  },

  {
    id: "100.1.0",
    section: 100,
    set: 1,
    items: [
      {
        label: "",
        fields: [
          {
            type: "persons-numbers",
            name: "persons-numbers",
            required: true,
            verification: "personnummer",
          },
        ],
      },
    ],
  },
  {
    id: "100.1.5",
    section: 100,
    set: 1,
    items: [
      {
        label: "Fyll i din adress för att färdigställa ditt testamente.",
        fields: [
          {
            type: "input",
            name: "address-will-address",
            placeholder: "Adress",
            required: true,
            verification: "text",
          },
        ],
      },
      {
        label: "",
        fields: [
          {
            type: "input",
            name: "address-will-postcode",
            placeholder: "Postnummer",
            required: true,
            verification: "text",
          },
          {
            type: "input",
            name: "address-will-area",
            placeholder: "Ort",
            required: true,
            verification: "text",
          },
        ],
      },
    ],
  },

  {
    id: "101.1.1",
    section: 101,
    set: 1,
    items: [
      {
        label: "",
        fields: [
          {
            type: "input",
            name: "address-will-address",
            placeholder: "Adress",
            required: true,
            verification: "text",
          },
        ],
      },
      {
        label: "",
        fields: [
          {
            type: "input",
            name: "address-will-postcode",
            placeholder: "Postnummer",
            required: true,
            verification: "text",
          },
          {
            type: "input",
            name: "address-will-area",
            placeholder: "Ort",
            required: true,
            verification: "text",
          },
        ],
      },
    ],
  },

  {
    id: "102.1.1",
    section: 102,
    set: 1,
    items: [
      {
        label: "",
        fields: [
          {
            type: "persons-numbers",
            name: "lpa-persons-numbers",
            required: true,
            verification: "personnummer",
          },
        ],
      },
    ],
  },
];

export const checkDepsWithinQuestionField = (
  i: FlowItemField,
  flow: FlowType,
  item: FlowItem,
  index?: number
): boolean => {
  if (i.dependencies && item.multiple) {
    const setData = flowSets.find(
      (set) => set.id === flow.set && set.section === flow.section
    );
    let values = flow.values.find((el) => el.name === item.multiple);
    if (setData?.multiple) {
      values = flow.values
        .find((el) => el.name === setData.multiple)
        ?.value[flow.setIndex]?.find((el) => el.name === item.multiple);
    }

    const toCheck = i.dependencies.reduce((acc, d) => {
      if (
        !values ||
        !(values.value && index !== undefined && values.value[index])
      )
        return acc;

      const valueToMatch = values.value[index].find((f) => f.name === d.name);
      if (!valueToMatch) return acc;

      const matchedValues = d.values.reduce((a, v) => {
        if (valueToMatch.value.includes(v)) {
          return [...a, v];
        }
        return a;
      }, [] as string[]);

      if (matchedValues.length === d.values.length) {
        return [...acc, d];
      }

      return acc;
    }, [] as string[]);

    if (i.dependencies.length !== toCheck.length) {
      return false;
    }
  }

  return true;
};
